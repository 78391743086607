@import "../../../variables.module.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: $color-white;
  box-shadow: 0 8px 20px 0 rgba(0,0,0,0.10);
  border-radius: 8px;

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: 88px;
  }

  .title {
    font-size: 20px;
    color: $color-master-C7;
    text-align: center;
    margin: 19px 0 0 0;
  }

  .desc {
    font-size: 14px;
    color: $color-master-C6;
    text-align: center;
    margin-bottom: 60px;
  }
}

.topContainer {
  background: #F0FDF9;
}

.bottomContainer {
  padding: 0 40px;
  margin-top: 20px;
}

.line {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  width: 100%;
  height: 8px;
}

.detailInfo {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;

  & > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    margin-right: 20px;
  }

  & > div:last-child {
    width: calc(100% - 60px);
    & > div:first-child {
      font-size: 18px;
      color: $color-master-C7;
    }

    & > div:last-child {
      font-size: 14px;
      color: $color-master-C5;
    }
  }
}