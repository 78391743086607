@import "../../../variables.module.scss";

.label {

  & > a {
    display: inline-block;
    background: $color-master-C2;
    border-radius: 4px;
    padding: 5px 14px;
    letter-spacing: 1px;
    cursor: pointer;
    color: $color-master-C6;

    &:hover {
      background: $color-master-C4;
    }
  }

  & > a:not(:first-child) {
    margin-left: 15px;
  }
}

.checked {
  background: $color-primary !important;
  color: $color-white !important;
}
