@import "../../../variables.module.scss";

.ruleContainer {
  margin-top: 50px;
  box-shadow: 6px 6px 18px 0 rgba(1,42,25,0.10);
  border-radius: 10px;
  padding: 30px;
}

.title {
  font-size: 16px;
  color: $color-master-C7;
  font-weight: 600;
  margin-bottom: 20px;
}

.ruleItems {
  margin-bottom: 50px;

  & > div {
    display: flex;

    & > span:nth-of-type(2) {
      width: calc(100% - 70px);
    }
  }

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }
}

@mixin prizeBox {
  border: 1px solid $color-master-C4;
  border-radius: 10px;
  padding: 20px 30px;
  text-align: center;
  width: 254px;
  color: $color-master-C6;
  font-weight: 600;

  @media (max-width: 1024px) {
    padding: 20px;
  }
}

.prizeContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;

  & > div {
    @include prizeBox();
  }
}

.pagePrize {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  & > div {
    @include prizeBox();
    flex: 1;
    padding: 20px 30px 36px;

    & > img {
      margin: 20px 0 55px;
      width: 100%;
    }
  }

  & > div:not(:last-child) {
    margin-right: 60px;

    @media (max-width: 1024px) {
      margin-right: 35px;
    }
  }
}

.anchor {
  color: $color-primary;
  text-decoration: underline;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $color-primary-dark;
    text-decoration: underline;
  }
}
