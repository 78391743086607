@import '../../../../variables.module.scss';

.container {
  padding: 0 14px 20px 14px;
  img.qrcode {
    width: 100%;
    margin-top: -20px;
  }
}

.titleBox {
  margin-left: 10px;
}

.title {
  font-size: 20px;
  color: $color-master-C7;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 8px;
  margin-top: 10px;
}

.titleTips {
  font-size: 14px;
  color: $color-master-C6;
  margin-bottom: 30px;
}

.header {
  padding: 15px 0;
}
