@import "../../../variables.module.scss";

.container {
  height: 100%;
  background: $color-white;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.10);
  border-radius: 4px;
  display: flex;
}

.leftContainer {
  flex: 4;
  padding: 30px;
}

.rightContainer {
  flex: 6;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 14px;
  color: $color-master-C7;
  padding: 0 30px;
}

.rightTitle {
  font-size: 24px;
  color: $color-master-C7;
  font-weight: 500;
  margin-bottom: 20px;
}

.switchContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.switchTop {
  flex: 6;
  display: flex;
  align-items: center;
  justify-content: center; 

  @media (max-height: 768px) {
    & > img {
      height: 230px;
    }
  }
  
}

.switchBottom {
  flex: 4;
  text-align: center;

  & > div:nth-of-type(1) {
    font-size: 20px;
    color: $color-master-C7;
    margin-bottom: 20px;
    margin-top: 6%;
  }

  & > div:nth-of-type(2) {
    font-size: 14px;
    color: $color-master-C5;
    margin-bottom: 60px;
  }
}

.settingContainer {
  position: relative;
  height: calc(100% - 72px - 72px);
}

.setWebsite {
  height: 70%;
}

.setTime {
  height: 30%;
  margin-top: 20px;
}

.greyBox {
  background: $color-master-C1;
  border-radius: 4px;
  padding: 20px;
}

.disableMask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background:rgba($color: $color-master-C1, $alpha: 0.6);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    background: rgba($color: $color-master-C7, $alpha: 0.6);
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.10);
    border-radius: 4px;
    font-size: 14px;
    color: $color-white;
    font-weight: 500;
    padding: 10px 18px;
  }
}

.disableTips {
  font-size: 12px;
  color: $color-white;
  text-indent: 32px;
  margin-top: 10px;
}

.lockIcon {
  display: inline-flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-white;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.confirmBtn {
  width: 450px;
  margin: 40px auto 0 auto;
}

.rightContent {
  height: 100%;
}

.whiteBox {
  background: $color-white;
  border: 1px solid $color-master-C4;
  border-radius: 4px;
  height: calc(100% - 30px);
  width: 100%;
  margin-top: 10px;
  overflow: auto;
  padding: 15px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px; 
  align-content: baseline;
}

.emptyContent {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: stretch;
}

.modalContent {
  width: 900px;
  height: 676px;
  background: $color-white;
  border-radius: 4px;
  padding: 40px 50px 33px 50px;
}

.modalTitle {
  font-size: 20px;
  color: $color-master-C7;
  text-align: center;
  margin-bottom: 30px;
}

.selectedWebsite {
  display: inline-flex;
  align-items: center;
  background: $color-white;
  border: 1px solid $color-primary;
  border-radius: 2px;
  padding: 2px 10px 2px 16px;
  font-size: 14px;
  color: $color-primary;
  cursor: pointer;

  & > span {
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &:hover {
      background: rgba(30,211,143,0.10);
    }

    & > span {
      font-size: 10px;
    }
  }
}

.operationContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  & > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed $color-master-C4;
    border-radius: 4px;
    flex: 1;
    padding: 15px 0;
    background-color: $color-white;
    text-align: center;
    font-size: 14px;
    color: $color-master-C7;
    cursor: pointer;

    & > span {
      margin-right: 15px;
      color: $color-master-C5;

      & > svg {
        font-size: 20px;
      }
    }
  }

  & > div:nth-of-type(1) {
    border: 1px dashed $color-primary;
    color: $color-primary;

    & > span {
      & > svg {
        color: $color-primary;
      }
    }
  }
}

.blackListItems {
  height: calc(100% - 100px);
  overflow: auto;
}

.blackListTitle {
  background: rgba($color: $color-white, $alpha: 0.6);
  border-radius: 4px;
  padding: 5px 24px;
  margin-bottom: 10px;
  font-size: 14px;
  color: $color-master-C5;
  display: flex;

  & > div:nth-of-type(1) {
    flex: 5;
  }

  & > div:nth-of-type(2) {
    flex: 3;

    @media (max-width: 1024px) {
      flex: 4;
    }
  }
}

.blackList {
  display: flex;
  background: $color-white;
  border-radius: 4px;
  padding: 8px 24px;
  margin-bottom: 10px;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  }

  & > div:nth-of-type(1) {
    flex: 5;
    display: flex;
    align-items: center;
    min-width: 0;
  }

  & > div:nth-of-type(2) {
    flex: 3;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1024px) {
      flex: 4;
    }

    & > span > img {
      cursor: pointer;
    }
  }
}

.exception {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: $color-function-warning;
  background: rgba(255,167,0,0.06);
  border-radius: 2px;
  padding: 4px 12px;

  & > img {
    margin-right: 5px;
  }
}

.allow {
  display: inline-flex;
  align-items: center;
  background: rgba(30,211,143,0.10);
  border-radius: 2px;
  font-size: 12px;
  color: $color-primary;
  padding: 4px 12px;
  cursor: pointer;

  & > img {
    margin-right: 5px;
  }

  & > span {
    margin-left: 5px;
  }
}

.forbidden {
  display: inline-flex;
  align-items: center;
  background: rgba(247,94,38,0.10);
  border-radius: 2px;
  font-size: 12px;
  color: $color-function-error;
  padding: 4px 12px;
  cursor: pointer;

  & > img {
    margin-right: 5px;
  }

  & > span {
    margin-left: 5px;
  }
}

.allowItem {
  font-size: 12px !important;
  color: $color-primary !important;
  display: flex;
  align-items: center;

  & > img {
    margin-right: 5px;
  }
}

.forbiddenItem {
  font-size: 12px !important;
  color: $color-function-error !important;
  display: flex;
  align-items: center;

  & > img {
    margin-right: 5px;
  }
}


.formContainer {
  padding: 20px 35px;
  background: $color-white;
  border-radius: 20px;
  width: 620px;
}

.deleteIcon {
  margin-left: 20px;
}

.iconContainer {
  margin-right: 24px;
  flex-shrink: 0;
}

.blackListName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  margin-right: 30px;
}

.menuItem {
  display: flex;
  align-items: center;

  & > img {
    margin-right: 5px;
  }
}

.balckListContainer {
  height: calc(100% - 144px);
  position: relative;
}