@import "../../../../variables.module.scss";

.operation {
  font-size: 16px;
  color: $color-white;
  cursor: pointer;

  &:hover {
    color: rgba($color-white, 0.6);
  }
}

.limitContent {
  height: 100%;
  width: 100%;
}

.popContent {
  position: relative;
  padding: 5px;
  width: 867px;
  height: 500px;
}

.pickerBg {
  background: $color-master-C1;
  
}

.btn {
  position: absolute;
  bottom: 5px;
  margin: auto;
  left: 0;
  right: 0;
  width: 350px;
}

.timeTitle {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  color: $color-master-C7;
  margin-bottom: 10px;
}
