@import "../../variables.module.scss";

.title {
  position: relative;
  margin: 0 auto 20px;
  border: 1px solid $color-primary;
  border-radius: 20px;
  display: flex;
  color: $color-master-C7;

  & > span {
    flex: 1;
    border-radius: 20px;
    padding: 5px 0;
    text-align: center;
    cursor: pointer;
  }
}

.activeTitle {
  color: $color-white;
  z-index: 1;
}

.slider {
  position: absolute;
  width: 50%;
  height: 100%;
  background: $color-primary;
  border: 1px solid $color-primary;
}

.left {
  left: -1px;
  transition: all 0.3s;
}

.right {
  left: 50%;
  transition: all 0.3s
}

.rightAngleTitle {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  & > span {
    flex: 1;
    padding: 20px 0;
    text-align: center;
    cursor: pointer;
  }
}

.activeRightAngleTitle {
  background: $color-white;
}

.normalRightAngleTitle {
  background: $color-master-C1;
}

.lineWithVerticalBarTitle {

  & > span {
    padding: 3px 0 3px;
    cursor: pointer;
  }

  & > span:nth-of-type(1) {
    position: relative;
    margin-right: 20px;

    &:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 1px;
      display: inline-block;
      height: 20px;
      width: 1px;
      background: $color-master-C4;
    }
  }

  & > span:nth-of-type(2) {
    margin-left: 20px;
  }

  .activeLineWithVerticalBarTitle {
    color: $color-master-C7;
    border-bottom: 2px solid  $color-primary;
  }

  .normalLineWithVerticalBarTitle {
    color: rgba(1, 42, 25, 0.6);
    border-bottom: 2px solid $color-white;
  }
}

.container {
  height: 100%;
}

.lineTitle {
  & > span {
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      color: $color-master-C7;
    }
  }

  .activeLineTitle {
    color: $color-master-C7;
    border-bottom: 2px solid  $color-primary;
  }

  .normalLineTitle {
    color: rgba(1, 42, 25, 0.6);
    border-bottom: 2px solid $color-white;
  }
}

.lineLarge {
  & > span {
    font-size: 16px !important;
  }

  .activeLineTitle {
    border-bottom: 3px solid  $color-primary !important;
  }

  .normalLineTitle {
    border-bottom: 3px solid $color-white !important;
  }
}

.rectangleTitle {
  display: flex;

  & > span {
    flex: 1;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;

    &:hover {
      color: $color-primary;
    }
  }

  .activeRectangleTitle {
    color: $color-primary;
    background: $color-master-C1;
    border-radius: 4px 0 0 4px;
    border-bottom: 2px solid  $color-primary;
  }

  & > span:nth-of-type(2) {
    border-radius: 0 4px 4px 0 !important;
  }

  .normalRectangleTitle {
    color: rgba(1, 42, 25, 0.6);
    border-bottom: 1px solid $color-master-C4;
  }
}

.rectangleLarge {
  & > span {
    font-size: 16px !important;
  }

  .activeRectangleTitle {
    border-bottom: 3px solid  $color-primary !important;
  }
}

.rectangleWithoutTitle {
  display: flex;

  & > span {
    flex: 1;
    padding: 15px 20px;
    cursor: pointer;
    text-align: center;
  }

  .activeRectangleWithoutTitle {
    color: $color-master-C7;
  }

  & > span:nth-of-type(2) {
    border-radius: 0 4px 4px 0 !important;
  }

  .normalRectangleWithoutTitle {
    background: $color-master-C1;
    border-radius: 4px 0 0 4px;
  }
}

.rectangleWithoutLarge {
  & > span {
    font-size: 16px !important;
  }
}
