@import "../../../../variables.module.scss";

.container {
  display: flex;
}

.tips {
  animation: passIn 0.3s ease;
  display: flex;
  align-items: center;
}

@keyframes passIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content {
  width: 340px;
  border-right: 1px solid $color-master-C4;
  padding-right: 45px;
  margin-top: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  animation: enter 0.3s ease-out;

  @media (max-width: 1366px) {
    height: 378px;
    margin-top: 34px;
  }
}

.deviceImg {
  margin: 60px 0 20px 0;
  height: 240px;
  display: flex;

  img {
    width: 100%;
  }
}

@mixin checkIcon {
  font-size: 20px;
  margin-right: 10px;
}

.yellowIcon {
  @include checkIcon();
  color: $color-function-warning;
}

.blueIcon {
  @include checkIcon();
  color: $color-function-info;
}

.desc {
  font-size: 20px;
  color: $color-master-C7;
}

.btn {
  margin-bottom: 70px;
  width: 242px;
}

.noData {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bg {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 497px;
  background-size: 100% 100%;
  background-image: url("../images/leftMobile.svg");
  background-repeat: no-repeat;
  width: 305px;
  animation: enter 0.3s ease-out;

  @media (max-width: 1366px) {
    height: 100%;
  }

  img {
    height: 80px;
    width: 90px;
  }

  .btn {
    width: 242px;
    margin-top: 100px;

    @media (max-width: 1366px) {
      width: 160px;
      margin-top: 60px;
    }
  }
}

@keyframes enter {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.cardList {
  flex: 1;
  overflow: auto;
}

.cardListContent {
  background: $color-master-C1;
  border-radius: 4px;
  height: calc(100% - 60px);
  padding: 20px 0;

  @media (max-width: 1440px) {
    padding: 15px 0;
  }

  @media (max-width: 1366px) {
    height: calc(100% - 50px);
  }
  @media (max-width: 1024px) {
    padding: 0;
  }
}

.cardListTitle {
  font-size: 18px;
  color: $color-master-C7;
  font-weight: bold;
  margin-bottom: 20px;
}

.pcCard {
  width: 450px;
  text-align: center;
}

.pcCardTitle {
  font-size: 20px;
  color: $color-master-C7;
  margin: 60px auto 15px auto;

  @media (max-width: 1440px) {
    margin: 30px auto 15px auto;
  }

  @media (max-width: 1366px) {
    margin: 10px auto 5px auto;
  }
}

.pcCardDesc {
  font-size: 14px;
  color: $color-master-C5;
  margin: 40px auto 60px auto;

  @media (max-width: 1440px) {
    margin: 30px auto 20px auto;
  }

  @media (max-width: 1366px) {
    margin: 20px auto 20px auto;
  }
}

.status {
  font-size: 14px;
  position: relative;
  padding-left: 10px;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    left: 0;
    top: 5px;
  }
}

.powerOn {
  color: $color-primary;

  &::before {
    background: $color-primary;
  }
}

.powerOff {
  color: $color-master-C5;

  &::before {
    background: $color-master-C5;
  }

  & > span {
    margin-left: 5px;
    cursor: pointer;
  }
}

.disabled {
  opacity: 0.5;
  background: $color-primary;
  box-shadow: 2px 4px 8px 0 rgba(30, 211, 143, 0.3);
  cursor: not-allowed;
}

.leftMobileCard {
  position: relative;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.screenShotBtn {
  margin-top: 40px;
  width: 202px;

  @media (max-width: 1024px) {
    margin-top: 15px;
  }
}

.loadingBox {
  background: $color-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  top: 230px;
  padding: 10px 20px;

  .loadingText {
    margin-left: 10px;
    font-size: 14px;
    color: $color-master-C7;
  }
}
