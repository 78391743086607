@import "../../../variables.module.scss";

.bannerContainer {
  position: relative;

  & img {
    width: 100%;
  }
}

.bannerCommonContainer {
  position: relative;

  & img {
    width: 100%;
  }
}

.platformContainer {
  display: flex;
  margin-top: 50px;

  @media (max-width: 1024px) {
    margin-top: 30px;
  }
}

.platformIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: $color-white;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.10);
  margin-right: 15px;
  cursor: pointer;

  & > img {
    width: 28px !important;
  }

  &:hover {
    background-color: $color-master-C1;
  }
}

.infoContainer {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 12vw;
  padding-left: 15%;
  text-align: left;

  & > img {
    width: 460px !important;

    @media (max-width: 1024px) {
      width: 368px !important;
    }
  }

  @media (max-width: 1440px) {
    padding-top: 10vw;
    padding-left: 12.5%;
  }

  @media (max-width: 1024px) {
    padding-left: 7.8%;
  }


  .desc {
    font-size: 24px;
    color: $color-master-C6;
    margin: 15px auto 30px auto;

    @media (max-width: 1024px) {
      font-size: 20px;
      margin: 10px auto 20px auto;
    }
  }
}

.commonContainer {
  position: absolute;
  width: 230px;
  height: 22vw;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  left: 0;
}

.tips {
  font-size: 16px;
  color: $color-master-C5;
  margin-top: 10px;
}

.funcContainer {
  width: 70%;
  margin: 0 auto;
  padding-bottom: 80px;

  @media (max-width: 1440px) {
    width: 75%;
  }

  @media (max-width: 1024px) {
    width: 84%;
  }
}

.funcTitle {
  font-size: 40px;
  color: $color-master-C7;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 80px;

  @media (max-width: 1024px) {
    font-size: 32px;
  }
}

.funcSubtitle {
  font-size: 20px;
  color: $color-master-C6;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 18px;
  }
}


.desc {
  margin-bottom: 30px;
  span {
    font-size: 18px;
    color: $color-master-C6;
  }
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $color-master-C4;
  cursor: pointer;
}

.selected {
  background: $color-primary;
  border-radius: 5px;
  width: 60px;
}

.activityBanner {
  position: relative;

  & > a {
    position: absolute;
    top: 30vw;
    left: 12%;
  }
}

.activityInfo {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 11vw;
  padding-left: 15%;
  text-align: left;

  @media (max-width: 1440px) {
    padding-top: 8vw;
    padding-left: 12.5%;
  }

  @media (max-width: 1024px) {
    padding-left: 7.8%;
  }
}

.activityText {
  display: block !important;
  width: 380px !important;
  margin-bottom: 30px;

  @media (max-width: 1024px) {
    width: 250px !important;
    margin-bottom: 15px;
  }
}

.carousel {
  margin-bottom: 80px;
}

.marginBottom {
  margin-bottom: 60px;
}

:global {
  .control-dots > span:not(:last-child) {
    margin-right: 15px;
  }
}
