@import '../../../variables.module.scss';

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: calc(100vh - 110px);
  background: $color-white;
  border-radius: 10px;
}

.headerContainer,
.qrCodeContainer,
.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.headerContainer {
  margin: 15px 0;
  flex: 1;
  .title {
    font-size: 24px;
    color: $color-master-C7;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .time {
    color: $color-master-C7;
  }
}

.header {
  font-size: 20px;
  color: $color-master-C7;
}

.subHeader {
  font-size: 14px;
  color: $color-master-C6;
}

.qrCodeContainer {
  flex: 4;
  background-color: rgba($color: $color-primary, $alpha: 0.05);
  font-size: 14px;
  color: $color-master-C6;

  img {
    height: 200px;
    width: 200px;
    margin-bottom: 40px;
    box-shadow: 4px 8px 12px 0 rgba(0, 0, 0, 0.1);
  }
}

.contactContainer {
  flex: 2;

  .title {
    font-size: 16px;
    color: $color-master-C5;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .phone {
    font-size: 24px;
    color: $color-master-C7;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .time {
    color: $color-master-C7;
  }
}
