@import "../../../variables.module.scss";

html {
  font-size: 100px;
}

.weixin {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
}

.content {
  padding-right: 0.2rem;
  display: flex;
  justify-content: flex-end;
}


.container {
  background-image: url('./images/background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 0.4rem;
  font-size: 0.16rem;
  color: $color-white;
}

.title {
  position: relative;
  display: inline-block;

  & > img:nth-of-type(2) {
    position: absolute;
    top: -10px;
    right: -45px;
  }
}

.desc {
  margin-top: 0.24rem;
}

.banner {
  margin-bottom: 0.5rem;

  img {
    width: 100%;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 0.1rem;
  }
}

.androidDownloadBtn {
  background: $color-white;
  border: 1px solid $color-white;
  border-radius: 20px;
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.16rem;
  color: $color-black;
  margin: 0 0.4rem 0.2rem 0.4rem;

  &:active, &:focus {
    background: rgba(51,51,51,0.01);
    border: 1px solid $color-white;
    color: $color-white;
  }
}

.downloadBtn {
  background: rgba(51,51,51,0.01);
  border: 1px solid $color-white;
  border-radius: 0.2rem;
  margin: 0 0.4rem 0.8rem 0.4rem;
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.16rem;
  color: $color-white;
}

.footer {
  font-size: 0.12rem;
  margin: 0 auto;
  width: 100%;

  a {
    color: $color-white;
  }
}

.wechat {
  margin-bottom: 0.16rem;

  img {
    margin-right: 0.08rem;
  }
}

.line {
  height: 1px;
  margin: 0 0.2rem 0.15rem 0.2rem;
  background-image:  linear-gradient(270deg, rgba(255,255,255,0.00) 0%, $color-white 50%, rgba(255,255,255,0.00) 100%);
}

.icp {
  margin-top: 0.07rem;
  margin-bottom: 0.08rem;

  & > img {
    width: 0.16rem;
    margin-right: 0.05rem;
  }
}

.bannerImage {
  animation: switchImg 2.5s infinite normal;
}

@keyframes switchImg {
  from {
      opacity: 0.5;
  }

  to {
      opacity: 1;
  }
}