@import "../../../variables.module.scss";

.container {
  padding-top: 50px;
}

.footerContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url("./images/footer.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding-top: 40px;

  @media (max-height: 900px) {
    padding-top: 60px;
  }
}

.linkItems {
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: $color-primary, $alpha: 0.05);

  & > div:not(:last-child) {
    margin-right: 66px;
  }

  a {
    color: rgba($color:$color-master-C7, $alpha: 0.8);
    font-size: 12px;

    &:hover {
      color: $color-master-C7;
    }
  }
}

.title {
  font-size: 20px;
  color: $color-master-C7;
  letter-spacing: 1px;
}

.emphasizeText {
  margin-bottom: 10px;
  font-size: 24px;
  color: $color-master-C7;
  letter-spacing: 1.71px;
}

.emphasizeRightText {
  opacity: 0.8;
  font-size: 14px;
  color: $color-master-C7;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 3px;
  & > img {
    margin-right: 10px;
  }
}

.normalText {
  display: block;
  opacity: 0.6;
  font-size: 12px;
  color: rgba($color: $color-master-C7, $alpha: 0.6);
  letter-spacing: 1px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 15% 20px 15%;

  @media (max-width: 1440px) {
    padding: 0 12.5% 35px 12.5%;
  }

  @media (max-width: 1024px) {
    padding: 0 7.8% 35px 7.8%;
  }

  & > div:first-child {
    & > img {
      margin-bottom: 25px;
    }
  }

  & > div:nth-child(2) {
    & > div:nth-child(2) {
      margin: 5px 0 20px 0;
    }
  }
}

.icpContainer {
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 10px 15% 10px 15%;

  @media (max-width: 1440px) {
    padding: 0 12.5% 10px 12.5%;
  }

  @media (max-width: 1024px) {
    padding: 0 7.8% 10px 7.8%;
  }

  & > div:nth-of-type(2) {
    font-size: 14px;
    color: rgba($color: $color-master-C7, $alpha: 0.8);
    line-height: 26px;
  }
}

.divideLine {
  border: 1px;
  height: 1px;
  margin: 0 0 15px;
  background: linear-gradient(270deg,hsla(0,0%,100%,0),hsla(210,1%,59%,.25),hsla(0,0%,100%,0));
}

.icpLink {
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 12px;
    margin: 0 5px 0 2px;
  }

  & > a:nth-of-type(1) {
    margin-right: 8px;
  }

  & > a {
    font-size: 14px;
    color: rgba($color-master-C7, 0.8);
    line-height: 26px;

    &:hover {
      opacity: 1;
    }
  }
}

.bg {
  background-color: $color-master-C1;
}

.leftContainer {
  display: flex;
  align-items: center;

  & > div:nth-of-type(1) {
    margin-right: 40px;
    font-size: 10px;
    color: $color-master-C6;
    text-align: center;

    & > img {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }
  }
}
