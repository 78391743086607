@import "../../../variables.module.scss";

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 40px;
  background-image: url("./images/headerBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 800px;
  color: $color-white;
}

.closeIcon {
  font-size: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.modalContent {
  border-radius: 0 0 10px 10px;
  padding: 20px 40px 40px;
  background-color: $color-white;
  height: 518px;
}

.userInfo {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;

  & > img {
    margin-right: 20px;
    width: 44px;
    height: 44px;
  }
}

.title {
  font-size: 16px;
  color: $color-master-C7;
  margin-bottom: 10px;
}

.packageInfo {
  color: $color-master-C6;
  margin: 10px 0 20px;
}

.payment {
  border: 1px solid $color-master-C4;
  border-radius: 4px;
  margin-top: 24px;
}

.tabContent {
  padding: 30px 15px 15px;
}

.tabTitle {
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    margin-right: 10px;
  }
}

.qrcodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-master-C7;
  margin-bottom: 13px;
}

.qrcode {
  border: 1px solid $color-master-C4;
  border-radius: 4px;
  width: 140px;
  height: 140px;
  margin-right: 50px;

  & > img {
    width: 100%;
  }
}

.price {
  font-size: 32px;
  color: $color-primary;
  font-weight: 600;
}

.tips {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $color-master-C6;
  margin-top: 19px;

  & > svg {
    margin-right: 5px;
  }
}

.protocol {
  font-size: 12px;
  color: $color-master-C5;
  text-align: right;

  & > a {
    color: $color-master-C5;
    text-decoration: underline;
  }
}

.paySuccessTitle {
  text-align: center;
  margin: 20px 0 20px;

  & > div {
    font-size: 16px;
    color: $color-primary;
    margin-top: 15px;
    letter-spacing: 1px;
    font-weight: 500;
  }
}

.paySuccessInfo {
  background: $color-master-C1;
  width: 560px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 273px;

  & > div:not(:first-child) {
    width: 302px;
    color: $color-master-C5;

    & > span {
      color: $color-master-C7;
      margin-left: 20px;
    }
  }

  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
}

.linkBtn {
  text-align: center;
  margin-top: 30px;

  & > a {
    display: inline-block;
  }

  & button {
    background: $color-white;
    border: 1px solid $color-primary;
    border-radius: 4px !important;
    color: $color-primary;
    width: 172px;

    &:hover {
      background: $color-primary-lightest;
    }
  }
}

.payFailedTitle {
  text-align: center;
  margin-top: 120px;
  margin-bottom: 40px;

  & > img {
    width: 40px;
    height: 40px;
  }

  & > div {
    font-size: 16px;
    color: $color-function-error;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 20px;
  }
}

.smallBtn {
  & > span {
    font-size: 12px;
  }
}

.activeCodeContainer {
  text-align: center;
  font-size: 14px;
  color: $color-master-C7 !important;
  border-bottom: 1px solid $color-master-C4;
  padding: 0 30px;
  margin-bottom: 20px !important;

  & > div {
    margin-bottom: 15px;
  }

  & > div:last-child {
    margin-bottom: 20px;
  }
}

.activeCode {
  font-size: 18px;
  color: $color-master-C7;
}

.codeTips {
  font-size: 12px;
  color: $color-master-C5; 
}

.codeDesc {
  color: $color-master-C6;
}

.copyText {
  margin-left: 12px;
  cursor: pointer;
}

:global {
  .ant-modal-content {
    border-radius: 10px;
  }
}

// 等待支付相关---------------------
.waitingUserConfirmContent {
  user-select: none;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 332px;
  width: 400px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
}

.waitingUserConfirmHeader {
  padding-top: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: end;
  background: #fff;
  border-radius: 10px 10px 0 0;
}
.waitingIcon {
  margin-top: 30px;
  margin-bottom: 34px;
  width: 100px;
  height: 100px;
}
.waitingTitle {
  font-size: 24px;
  color: #3a3a3a;
  margin-bottom: 20px;
}

.waitingBodyText {
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-bottom: 60px;
}
.checkPay {
  width: 100%;
  height: 48px;
  line-height: 48px;
  background: #1ed38f;
  box-shadow: 2px 4px 8px 0px rgba(30, 211, 143, 0.31);
  border-radius: 99px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
}
