@import "../../../variables.module.scss";

.content {
  width: 336px;
  font-size: 14px;
  color: $color-master-C6;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 20px;
}

.popover {
  & > div > div:nth-of-type(2) {
    & > div:nth-of-type(1) {
      border-bottom: 1px solid $color-master-C3;
      padding: 10px 20px;
      margin-bottom: 0;
      font-size: 14px;
      color: $color-master-C7;
      font-weight: 500;
    }

    & > div:nth-of-type(2) {
      padding: 15px 20px;
    }
  }
}

.descIcon {
  cursor: pointer;
  opacity: 0.8;
  padding-left: 14px;

  &:hover {
    opacity: 1;
  }
}