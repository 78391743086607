@import "../../../variables.module.scss";

.detail {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;

  ul {
    padding-inline-start: 0;
    margin-bottom: 0;

    li {
      font-size: 14px;
      color: $color-master-C5;
      margin-bottom: 5px;
      list-style: none;
    }
  }

  & > div:nth-child(2) {
    & > div:first-child {
      span {
        font-size: 18px;
        color: $color-master-C7;
        margin-bottom: 5px;
      }
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: 40px;
  width: 40px;
  margin-right: 20px;
  margin-top: 5px;
}

.download {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  background: $color-master-C7;
  border-radius: 8px;
  height: 40px;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background: rgba($color: $color-master-C7, $alpha: 0.8);
  }

  img {
    margin-right: 5px;
    height: 20px;
    width: 20px;
  }

  span {
    font-size: 16px;
    color: $color-white;
    font-weight: bold;
  }
}

.showFunImage {
  animation: showDeleteIcon 0.5s forwards;
}

@keyframes showDeleteIcon {
  from { transform: translateY(100px); }
  to { transform: translateY(0); }
}

.funcItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 150px;
}

.funcDesc {
  width: calc(100% - 820px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 460px;

  @media (max-width: 1600px) {
    width: calc(100% - 680px);
  }

  @media (max-width: 1366px) {
    width: calc(100% - 620px);
  }

  @media (max-width: 1024px) {
    width: calc(100% - 485px);
  }

  & > div:nth-of-type(1) {
    font-size: 28px;
    color: $color-master-C7;
    margin-bottom: 10px;
  }

  & > div:nth-of-type(2) {
    font-size: 20px;
    color: $color-master-C6;
    margin-bottom: 40px;
  }
}

.funcImage {
  text-align: center;

  & > img {
    transform: translateY(100px);
    width: 600px;

    @media (max-width: 1366px) {
      width: 540px;
    }

    @media (max-width: 1024px) {
      width: 450px;
    }
  }
}