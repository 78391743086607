@import "../../../variables.module.scss";

.container {
  display: flex;
  width: 70%;
  margin: 0px auto;
  padding: 23vh 0 10vh 0;
  height: 100vh;

  @media (max-width: 1600px) {
    width: 82%;
  }

  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 1366px) {
    height: 660px;
  }
}

.functionPanel {
  display: flex;
  align-items: flex-start;
  height: 120px;
  padding-bottom: 30px;
}

.activeTitle {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: $color-master-C7;
  font-weight: bold;
  padding-top: 1px;
  width: 100px;

  &>span {
    margin-left: 10px;
  }
}

.normalTitle {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: rgba($color: $color-master-C7, $alpha: 0.6);
  font-weight: bold;
  padding-top: 1px;
  width: 100px;

  &>span {
    margin-left: 10px;
  }
}

.common {
  height: 36px;
  font-size: 18px;
  cursor: pointer;
}

.active,
.active:hover {
  @extend .common;
  display: inline-block;
  color: $color-primary;

  &::after {
    content: '';
    height: 3px;
    background: $color-primary;
    border-radius: 10px;
    display: block;
  }
}

.normal {
  @extend .common;
  color: $color-master-C7;
  list-style: none;
}

.tabContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 2.5;

  @media (max-width: 1440px) {
    flex: 2;
  }
}

.tabTitle {
  font-size: 24px;
  color: $color-master-C7;
  font-weight: bold;
  margin-bottom: 5px;
}

.tabContent {
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  height: 100%;
  flex: 8;


  @media (max-width: 1440px) {
    flex: 7;
  }

  @media (max-width: 1024px) {
    flex: 2.5;
  }

  &>div {
    height: 100%;

    @media (max-width: 1024px) {
      height: 60%;
    }
  }

  &>div:first-child {
    display: flex;
    flex-direction: column;
    padding-top: 18vh;
    flex: 3;

    @media (max-width: 1440px) {
      padding-top: 16vh;
      flex: 1.5;
    }

    @media (max-width: 1366px) {
      padding-top: 4vh;
    }

    @media (max-width: 1024px) {
      flex: 3;
      padding-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
    }
  }

  &>div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 5;
    margin-top: 60px;

    @media (max-width: 1440px) {
      flex: 2.5;
    }


    @media (max-width: 1024px) {
      flex: 3;
      margin-top: 0px;
    }


  }
}

.info {
  color: $color-master-C5;
}

.textCenter {

  @media (max-width: 1024px) {
    text-align: center;
  }
}

.desc {
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;

  &>span {
    display: inline-flex;
  }
}

.logLink,
.logLink:hover {
  font-size: 14px;
  color: $color-master-C5;
  text-decoration: underline;
}

.btn {
  margin-top: 50px;
  width: 228px;
}

.verticalBar {
  display: inline-block;
  margin: 0 4.5px;
  height: 20px;
  border-left: 2px solid $color-master-C4;
}

.imageContainer {
  display: flex;
  flex-direction: row !important;

  @media (max-width: 1700px) {
    &>img {
      max-width: 600px;
    }
  }


  @media (max-width: 1366px) {
    &>img {
      height: 420px;
    }
  }

  @media (max-width: 1024px) {
    &>img {
      height: 270px;
    }
  }
}

.items {
  display: flex;
  height: 100%;


  &>ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: self-start;
    margin: 0;
  }
}

.tips {
  font-size: 12px;
  color: $color-master-C5;
  margin-bottom: 30px;

  &>img {
    margin-right: 5px;
    margin-bottom: -2px;
  }
}