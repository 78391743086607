@import "../../../variables.module.scss";

.container {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0 100px;
  font-size: 14px;
  color: $color-master-C6;
}

.nav {
  font-size: 16px;
  color: $color-master-C5;
  margin-bottom: 40px;

  & > .icon {
    margin: 0 5px;
  }
}

.labelTitle {
  font-size: 16px;
  color: $color-master-C7;
  font-weight: 600;
  margin-right: 20px;
}

.title {
  font-size: 32px;
  color: $color-master-C7;
}

.time {
  font-size: 14px;
  color: $color-master-C5;
  margin-bottom: 30px;
  margin-top: 10px;

  & > span:not(:first-child) {
    position: relative;
    margin-left: 10px;
    padding-left: 10px;

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $color-master-C5;
    }
  }
}

.content {
  margin: 40px 0;
  padding: 40px 0;
  border-top: 1px solid $color-master-C4;
  border-bottom: 1px solid $color-master-C4;
  font-size: 16px;

  & img {
    display: block;
    margin: 40px auto;
  }
}

.prevAndNext {
  margin-bottom: 80px;

  & > div:nth-of-type(1) {
    margin-bottom: 15px;
  }
}

.prefer {
  font-size: 16px;
  color: $color-master-C7;
  margin-bottom: 30px;
}

.preferLinks {
  & > div:not(:last-child) {
    margin-bottom: 20px;
  }

  & a {
    font-size: 14px;
    color: $color-master-C6;

    &:hover {
      text-decoration: underline;
    }
  }
}
