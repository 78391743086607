@import "../../../../variables.module.scss";

.footer {
  font-size: 0.12rem;
  color: $color-master-C5;
  padding: 0.3rem 0;
  text-align: center;

  & a {
    color: $color-master-C5;
  }

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
}
