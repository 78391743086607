@import "../../variables.module.scss";

@mixin outline(){
  background: $color-white;
  border-radius: 8px;
  font-size: 16px;
  height: 52px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  cursor: pointer;

  &:hover {
    color: $color-white;
  }
}

.outline-blue {
  @include outline();
  border: 1px solid $color-function-info;
  box-shadow: 2px 4px 8px 0 rgba(64,111,255,0.20);
  color: $color-function-info;

  &:hover {
    background: $color-function-info;
    box-shadow: 0 2px 4px 0 rgba(64,111,255,0.20);
  }
}

.outline-yellow {
  @include outline();
  border: 1px solid $color-function-warning;
  box-shadow: 2px 4px 8px 0 rgba(255,182,2,0.20);
  color: $color-function-warning;

  &:hover {
    background: $color-function-warning;
    box-shadow: 0 2px 4px 0 rgba(255,182,2,0.20);
  }
}

.outline-green {
  @include outline();
  border: 1px solid $color-primary;
  box-shadow: 2px 4px 8px 0 rgba(42,194,139,0.20);
  color: $color-primary;

  &:hover {
    background: $color-primary;
    box-shadow: 0 2px 4px 0 rgba(42,194,139,0.20);
  }
}

@mixin primary() {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  font-size: 18px;
  color: $color-white;
  border-radius: 8px;
  padding: 0 60px;
  cursor: pointer;
}

.primary-blue {
  @include primary();
  background: $color-function-info;
  box-shadow: 2px 4px 8px 0 rgba(64,111,255,0.30);

  &:hover {
    background-color: $color-function-info-dark;
  }
}

.primary-yellow {
  @include primary();
  background: $color-function-warning;
  box-shadow: 2px 4px 8px 0 rgba(255,182,2,0.30);

  &:hover {
    background-color: $color-function-warning-dark;
  }
}

.primary-green {
  @include primary();
  background: $color-primary;
  box-shadow: 4px 8px 12px 0 rgba(42,194,139,0.20);

  &:hover {
    background: $color-primary-dark;
  }
}

.disableBtn {
  opacity: 0.6;
  cursor: not-allowed !important;
}

