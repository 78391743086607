@import "../../../variables.module.scss";

.container {
  width: 70%;
  margin: 0 auto;
  padding-top: 60px;
  font-size: 14px;
  color: $color-master-C6;
}

.nav {
  font-size: 16px;
  color: $color-master-C5;
  margin-bottom: 60px;
}

.navIcon {
  margin: 0 5px;
}

.newsItem {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid $color-master-C4;
}

.title {
  font-size: 18px;
  color: $color-master-C7;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.time {
  font-size: 14px;
  color: $color-master-C5;
  margin-bottom: 30px;

  & > span:nth-of-type(2) {
    position: relative;
    margin-left: 10px;
    padding-left: 10px;

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $color-master-C5;
    }
  }
}

.labels {
  margin-bottom: 20px;
}

.content {
  font-size: 16px;
  color: $color-master-C6;
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pagination {
  text-align: right;
  margin-bottom: 100px;
}

.checkedLabel {
  background: $color-primary !important;
  color: $color-white;
}
