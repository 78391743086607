@import "../../../variables.module.scss";

.outer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  width: 80%;
  margin: auto;
  text-align: center;
  animation: show 0.3s ease-in;
}

@keyframes show {
  from {
    bottom: 0;
  }
  to {
    bottom: 60px;
  }
}

.container {
  display: inline-flex;
  align-items: center;
  padding: 10px 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
}

.operations {
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 15px;
  }
}

.operation {
  background: $color-master-C6;
  border: 1px solid $color-white;
  border-radius: 4px;
  padding: 5px 16px;
  font-size: 14px;
  color: $color-white;
  cursor: pointer;

  &:hover {
    color: rgba($color-white, 0.8);
    border: 1px solid rgba($color-white, 0.8);
  }
}

.time {
  background: rgba($color-black, 0.1);
  border-radius: 20px;
  padding: 3px 10px;
}

.timeOperation {
  & > span:nth-of-type(2) {
    margin: 0 5px;
  }

  & > span:nth-of-type(3) {
    margin-right: 10px;
  }
}

.checked {
  font-size: 14px;
  color: $color-white;
  margin-right: 40px;

  & > span {
    font-size: 16px;
    padding: 3px 10px;
    background: rgba($color-black, 0.1);
    border-radius: 13px;
    margin-right: 8px;
  }
}

.closeIcon {
  font-size: 20px;
  color: $color-white;
  margin-right: 40px;
  cursor: pointer;
}

.greenChecked {
  border: 1px solid $color-primary;
  background: $color-primary;

  &:hover {
    border: 1px solid $color-primary;
  }
}

.redChecked {
  border: 1px solid $color-function-error;
  background: $color-function-error;
  &:hover {
    border: 1px solid $color-function-error;
  }
}

.yellowChecked {
  border: 1px solid $color-function-warning;
  background: $color-function-warning;
  &:hover {
    border: 1px solid $color-function-warning;
  }
}

.popContent {
  padding: 5px;
  width: 360px;
}

.timeTitle {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  color: $color-master-C7;
  margin-bottom: 10px;
}
