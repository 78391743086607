@import "../../../variables.module.scss";

.container {
  margin-bottom: 60px;
}

.labelTitle {
  font-size: 16px;
  color: $color-master-C7;
  font-weight: 600;
  margin-right: 20px;
}

