@import "../../../variables.module.scss";

.timeContainer {
  display: flex;

  & > div:nth-of-type(1) {
    width: 40px;
    background: $color-white;
    border: 1px solid $color-master-C3;
    border-right: none;
    border-radius: 4px 0 0 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > span {
      writing-mode: vertical-lr;
      margin-top: 10px;
      letter-spacing: 5px;
    }
  }

  & > div:nth-of-type(2) {
    flex-grow: 1;
  }
}

.timeBox {
  position: relative;
  display: flex;
  gap: 2px;

  & > div {
    flex: 1;
  }
}

.timeItem {
  display: flex;

  & > div {
    flex: 1;
    height: 60px;
    background: $color-master-C2;
    border: 1px solid $color-white;
    color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.smallAddIcon {
  width: 18px;
}

.last {
  position: relative;
}

.timeTop {
  position: absolute;
  top: -21px;
  left: 68%;
  font-size: 12px;
  color: $color-master-C5;
}

.timeBottom {
  position: absolute;
  bottom: -21px;
  left: 68%;
  font-size: 12px;
  color: $color-master-C5;
}

.startTime {
  left: -15px;
}
