@import "../../../variables.module.scss";

.container {
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background: $color-white;

  & > div {
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
  }

  & img {
    height: 40px;
  }
}

.content {
  padding-top: 160px;
  width: 70%;
  margin: 0 auto;
  max-width: 1062px;
  padding-bottom: 70px;

  @media (max-width: 1024px) {
    width: 75%;
  }
}

.title {
  font-size: 40px;
  color: $color-master-C7;
  text-align: center;
  margin-bottom: 80px;

  & > div:nth-of-type(1) {
    margin-bottom: 10px;
  }

  & > a {
    font-size: 16px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;

  & > svg {
    transform: rotate(180deg);
    font-size: 25px;
    margin-left: 15px;
  }
}
