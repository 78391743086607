@import "../../../variables.module.scss";

.container {
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  background: $color-white;

  & > div {
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
  }

  & img {
    height: 40px;
  }
}

.content {
  padding-top: 60px;
  width: 70%;
  margin: 0 auto;
  max-width: 1062px;

  @media (max-width: 1024px) {
    width: 85%;
  }
}

.publishContainer {
  margin-top: 40px;
}

.publishTitle {
  position: relative;
  font-size: 16px;
  color: $color-master-C7;
  font-weight: 600;
  padding-left: 15px;
  margin-bottom: 10px;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 1px;
    left: -1px;
    width: 5px;
    height: 20px;
    background: $color-primary;
    border-radius: 2px;
  }
}

.textAreaContainer {
  position: relative;
}

.textLength {
  position: absolute;
  bottom: -5px;
  right: 0;
  color: $color-master-C5;
}

.textArea {
  resize: none;
  border: 1px solid $color-master-C4;
  border-radius: 10px;
  height: 150px !important;
  margin-bottom: 20px;
}

.showContainer {
  margin-top: 77px;
  padding-bottom: 118px;
}

.emptyContent {
  text-align: center;
  padding-top: 100px;
  color: $color-primary;

  & > img {
    margin-right: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 20px;
}
