@import "../../../../variables.module.scss";

.tabListConatiner {
  padding: 20px 40px 30px 40px;
  background: #F0FDF9;
}

.tabList {
  display: flex;
  justify-content: space-between;
  background: rgba(89,233,196,0.16);
  border-radius: 8px;

  & > span {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: 1;
    padding: 10px;
    border-radius: 8px;
    color: $color-master-C6;

    &:hover {
      background: rgba(89,233,196,0.10);
    }
  }
}

.activeTab {
  background: $color-white;
  position: relative;
  color: $color-master-C7;
  font-weight: bold;
  &:hover {
    background: $color-white !important;
  }
}

.arrow {
  position: absolute;
  top: 75px;
  right: calc(50% - 18px);
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #FFF;
}

.logo {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

