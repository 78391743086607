@import "../../../variables.module.scss";

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  transition: width 0.1s;
}

.linkItem {
  display: flex;
  align-items: center;
  padding: 9px 0 9px 30px;
  font-size: 16px;
  color: $color-master-C7;
  margin-top: 25px;
  border-left: 3px solid transparent;
  opacity: 0.8;

  & > span {
    margin-left: 9px;
  }

   svg {
    margin-right: 10px;
    font-size: 18px;
    opacity: 0.8;
  }

  &:hover {
    background: $color-master-C1;
    color: $color-master-C7;
    border-radius: 10px;

    svg {
      opacity: 1 !important;
    }
  }
}

.linkItemGroup {
  position: relative;
}

.bottomExtra {
  position: absolute;
  height: 30px;
  border-radius: 10px;
  width: 179px;
  bottom: -6px;
  left: 10px;
  z-index: -1;
  opacity: 0.5;
  background: $color-master-C7;
}

.activeLink {
  color: $color-white !important;
  opacity: 1 !important;
  background-color: $color-master-C7 !important;
  border-radius: 10px;

  svg {
    color: $color-white !important;
    opacity: 1;
  }
}

.disableLink {
  opacity: 0.5;
  cursor: not-allowed;

  &:hover {
    color: $color-master-C7 !important;
    opacity: 0.5 !important;

    & > svg {
      color: $color-master-C7;
    }
  }
}

.cardContainer {
  padding:  0 20px 20px;
  color: $color-master-C6;
}

.minNavContainer {
  position: relative;
  width: 60px;
  padding: 20px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  transition: width 0.1s;
  border-right: 1px solid $color-master-C4;
}

.minLinkItem {
  display: flex;
  height: 50px;
  width: 50px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $color-master-C7 ;
  margin: 0 auto 25px;

  svg {
    opacity: 0.8;
  }

  &:hover {
    background: $color-master-C1;
    border-radius: 10px;
    color: $color-master-C7;

    svg {
      opacity: 1 !important;
    }
  }
}

.miniLogoStyle {
  margin-bottom: 40px;
}

.activeMinLink {
  background: $color-master-C7 !important;
  border-radius: 10px;
  margin: 0 auto 25px;

  svg {
    color: $color-white !important;
    opacity: 1 !important;
  }
}

.minLinkItemGroup {
  position: relative;
}

.minBottomExtra {
  position: absolute;
  height: 15px;
  width: 38px;
  border-radius: 10px;
  opacity: 0.5;
  background: $color-master-C7;
  z-index: 6;
  bottom: -4px;
  margin-left: 10px;
}

.queen {
  position: relative;
}

.minMemberCard {
  position: absolute;
  left: 70px;
  bottom: 0;
  z-index: 199;
  animation: bounceOut; 
  animation-duration: 1.1s;
  animation-timing-function: cubic-bezier(.12,1.61,0,.85);
}

@keyframes bounceOut {
  0.00% {transform: translate3d(-150px, 0, 0) scale(0);}
  21.39% {transform: translate3d(-16.09px, 0, 0) scale(0.9391);}
  66.31% {transform: translate3d(0.38px, 0, 0) scale(1);}
  70% {transform: translate3d(0.1px, 0, 0) scale(1);}
  100.00% {transform: translate3d(-0.02px, 0, 0) scale(1);}
}

.memberIcon {
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.root {
  position: relative;
}

.links {
  padding: 0 20px;

  .homeLink {
    img {
      margin: 20px 0 15px 0;
    }
  }
}

.navIcon {
  width: 18px;
  height: 18px;
}

.normalLink {
  & > img {
    opacity: 0.8;
  }

  &:hover {
    & > img {
      opacity: 1;
    }
  }
}