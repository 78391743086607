@import "../../../../variables.module.scss";

.container {
  padding-top: 20px;
  padding-bottom: 10px;
  background-image: linear-gradient(180deg, rgba(#B2EAFE, 0.45) 0%, rgba(#D9F6FF, 0.45) 19%);

  @media (min-width: 1025px) {
    background-size: 500px;
  }
}

.registerContainer {
  position: relative;
}

.register {
  display: block;
  margin: 0 auto;
  width: 76%;
  position: absolute;
  bottom: 4vh;
  left: 0;
  right: 0;
  

  @media (min-width: 1025px) {
    width: 300px;
  }
}

.content, .content2 {
  width: 100%;
  @media (min-width: 1025px) {
    display: block;
    width: 400px;
    margin: 0 auto;
  }
}

.content2 {
  margin-top: 20px;
  margin-bottom: 20px;
}



:global {
  .ant-form-item-has-error .ant-input:not([disabled]):hover, .ant-form-item-has-error .ant-input-affix-wrapper:not([disabled]):hover {
   border-color: $color-function-error !important; 
  }

  .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
    border-color: $color-function-error !important; 
  }

}
