.captchaContainer {
  display: flex;

  & > button {
    margin-left: 15px;
  }
}
.captchaContainerSem {
  display: flex;
  flex-wrap: nowrap;
  & > button {
    margin-left: 15px;
  }
}

.agreement,
.submitBtn {
  margin-bottom: 20px !important;
}

.submitBtn {
  .loginBtn {
    padding: 0 10px;

    button {
      width: 100% !important;
    }
  }
  .radiuBtn {
    position: fixed;
    border-radius: 23px;
    overflow: hidden;
    width: 350px;
    bottom: 40px;
    left: calc(50% - 175px);
  }
}
:global {
  .radius-check {
    .ant-checkbox .ant-checkbox-inner {
      border-radius: 50% !important;
    }
  }
  .radius-check:hover {
    .ant-checkbox .ant-checkbox-inner {
      border-radius: 50% !important;
    }
  }
}
