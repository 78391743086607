// master
$color-master-C1: #F8F8F8;
$color-master-C2: #F2F2F2;
$color-master-C3: #EEEEEE;
$color-master-C4: #DDDDDD;
$color-master-C5: #999999;
$color-master-C6: #666666;
$color-master-C7: #333333;

// primary
$color-primary-008: #EDFCF6;  // $color-primary opacity 0.08
$color-primary-006: #F2FDF9; // $color-primary opacity 0.06
$color-primary: #1ED38F;
$color-primary-dark: #1AB97D;

// function
$color-function-error: #FE593C;
$color-function-error-dark: #DF4E34;
$color-function-error-006: #FFF5F4;
$color-function-warning: #FFA700;
$color-function-warning-dark: #E09200;
$color-function-warning-006: #FFFAF0;
$color-function-info: #009DFF;
$color-function-info-dark: #0089E0;
$color-function-info-006: #F0F9FF;

// others
$color-white: #FFFFFF;
$color-black: #000000;
$color-C1: #d5cc69;


// todo: delete
$color-master-hover: #f3f5f4;
$color-primary-lightest: #ddf0ec;
$color-primary-darker: #1b826d;
$color-secondary-lightest: #ffe7de;
$color-secondary: #ff5e23;
$color-secondary-dark: #f05820;
$color-secondary-darker: #d14d1c;

$color: #B2EAFE;
$color: #D9F6FF;
$color: #FBFBFB;

* {
  box-sizing: border-box;
}

body {
  font-size: 14px;
}

p {
  margin-top: 0;
}

a {
  text-decoration: none;
  color: $color-primary;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color .3s;
  transition: color .3s;
  -webkit-text-decoration-skip: objects;

  &:hover {
    color: $color-primary-dark;
  }
}

:export {
  primaryColor: $color-primary;
  colorMasterC1: $color-master-C1;
  colorMasterC2: $color-master-C2;
  colorMasterC3: $color-master-C3;
  colorMasterC7: $color-master-C7;
  colorFunctionWarning: $color-function-warning;
  colorFunctionError: $color-function-error;
  colorFunctionInfo: $color-function-info;
  colorWhite: $color-white;
}
