@import '../../../../variables.module.scss';

.modalContainer {
  background: $color-white;
  border-radius: 4px;
  padding: 40px 60px;
  width: 620px;
  text-align: center;
}

.modalTitle {
  font-size: 20px;
  color: $color-master-C7;
  margin-bottom: 15px;
}

.tips {
  font-size: 14px;
  color: $color-master-C6;
  background: rgba(30,211,143,0.08);
  border: 1px solid rgba(30,211,143,0.40);
  border-radius: 4px;
  padding: 6px 16px;
  display: flex;
  align-items: center;

  & > img {
    margin-right: 5px;
  }
}

.workOrderForm {
  margin: 0 auto;
  margin-top: 24px;
  text-align: left;
  border-radius: 4px;

  & > div:not(:last-child) {
    margin-bottom: 25px;
  }

  & input {
    border-radius: 4px !important;
  }
}

.formTitle {
  font-size: 14px;
  color: $color-master-C7;
  margin-right: 20px;
}

.websiteType {
  display: flex;
  flex-grow: 1;

  & > span {
    flex: 1;
    display: inline-block;
    position: relative;
    padding: 8px 16px;
    background: $color-white;
    border: 1px solid $color-master-C3;
    border-radius: 4px;
    font-size: 14px;
    color: $color-master-C6;
    cursor: pointer;

    & > img {
      width: 20px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  & > span:nth-of-type(1) {
    margin-right: 24px;
  }
}

.typeSelected {
  border: 1px solid $color-primary !important;
  color: $color-primary !important;
}

.courseInput {
  position: relative;
  flex-grow: 1;

  & > span > div:nth-of-type(1):hover {
    border: 1px dashed $color-primary;
  }
}

.courseTips {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: $color-function-error;
  font-size: 12px;
}

.setBtn {
  width: 350px;
  height: 48px;
  border-radius: 4px;
  font-size: 18px !important;
}

.disableBtn {
  width: 350px;
  height: 48px;
  border-radius: 4px;
  font-size: 18px !important;
  background-color: rgba($color: $color-primary, $alpha: 0.5) !important;
  color: $color-white !important;
  border-color: transparent !important;
}

.formItem {
  display: flex;
  align-items: center;
}

.textTop {
  align-items: flex-start;
}

.setTitleWidth {
  width: 98px;
}

.methodTips {
  font-size: 12px;
  color: $color-master-C5;
  display: flex;
  align-items: center;

  & > img {
    margin-right: 5px;
  }

  & > a {
    font-size: 12px;
    color: $color-master-C5;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}

.websiteTips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.downloadTemplate {
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > a {
    color: $color-primary;

    &:hover {
      color: $color-primary-dark;
    }
  }

  & > img {
    margin-right: 5px;
  }
}

.uploadText {
  font-size: 14px;
  color: $color-master-C5;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    margin-right: 5px;
  }
}

.uploadTips {
  font-size: 12px;
  color: rgba(153,153,153,0.80);
  margin-top: 10px;
}

.confirmModalContainer {
  background: $color-white;
  border-radius: 4px;
  padding: 40px;
  width: 620px;
}

.confirmModalcontent {
  position: relative;
  margin-left: 40px;
}

.infoIcon {
  font-size: 26px;
  color: $color-function-warning;
  margin-right: 15px;
  position: absolute;
  left: -40px;
}

.confirmModalTitle {
  font-size: 20px;
  color: $color-master-C7;
  margin-bottom: 15px;
}

.confirmModalbtn {
  text-align: right;
  margin-top: 40px;

  & > div:first-child {
    background: $color-white;
    border: 1px solid $color-master-C4;
    font-size: 16px;
    color: $color-master-C7;

    &:hover {
      background: $color-master-C1;
      color: $color-master-C6;
    }
  }
  & > div {
    width: 72px;
    height: 40px;
    margin-left: 15px;
    border-radius: 4px;

    box-shadow: none;
  }
}

.confrimBtn  {
  text-align: right;

  & > div {
    width: 104px;
    height: 40px;
    margin-left: 15px;
    border-radius: 4px;
    box-shadow: none;
  }
    
}