@import "../../../variables.module.scss";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
  z-index: 1;
  font-size: 16px;
  color: $color-master-C7;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;

  & > div:last-child {
    & > span:first-child {
      margin-right: 40px;
    }
  }
}

.link {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  & > a {
    font-size: 16px;
    color: $color-master-C7;
    margin-top: 18px;

    span {
      padding: 10px 20px;
    }

    &:hover {
      color: $color-primary;
    }
  }

  & > a:not(:last-child) {
    margin-right: 15px;
    
  }
}

.activeLink {
  color: $color-primary !important;

  &::after {
    content: '';
    height: 3px;
    background:$color-primary;
    border-radius: 10px;
    display: block;
    margin-top: 13px;
  }
}

.normalLink {
  &::after {
    content: '';
    height: 3px;
    background: transparent;
    border-radius: 10px;
    display: block;
    margin-top: 13px;
  }
}

.loginBtn {
  color: $color-master-C7;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    color: $color-primary;
  }
}

.registerBtn {
  font-size: 16px;
  color: $color-white;
  box-shadow: 4px 4px 10px 0 rgba(27,130,109,0.30);
  border-radius: 4px;

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.08), 0 4px 6px 0 rgba(0,0,0,0.10);
  }
}

.logo {
  height: 40px;
}
