@import "../../../../variables.module.scss";

.head {
  display: inline-block;
  margin-bottom: 10px;
}

.head p {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: $color-master-C7;
  text-align: left;
  display: inline;
  margin-right: 15px;
}

.card {
  position: relative;
  height: 124px;
}

.content {
  padding-top: 20px;
  padding-left: 30px;

  & > div:first-child {
    display: flex;
    align-items: center;

    & > img {
      margin-right: 10px;
    }
  }
}

.error {
  font-size: 18px !important;
  color: $color-function-error !important;
  font-weight: bold !important;
}

.error .content {
  height: 124px;
  border: 1px solid $color-master-C4;
}

.normal .content {
  border: 1px solid $color-master-C4;
  border-top: 0px;
  height: 120px;
}

.normal .separation {
  height: 4px;
  width: 100%;
}

.error .separation {
  display: none;
}

.separation {
  position: absolute;
}

.content {
  position: relative;
}

.title {
  font-weight: bold;
  font-family: PingFangSC-Medium;
  color: $color-master-C7;
  font-size: 18px;
  height: 25px;
  margin-top: 0;
  margin-bottom: 0;
}

.subTitle {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: $color-master-C6;
  height: 20px;
  text-align: left;
  margin-top: 5px;
}

.action {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.disConnect {
  margin-right: 10px;
}

.reConnect {
  border: 0px;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: $color-white;
  text-align: center;
  background: $color-primary;
  border-radius: 3px;
  width: 88px;
  height: 34px;
}