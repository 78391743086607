@import '../../../../variables.module.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    width: 78px;
    position: relative;
    font-size: 18px;
    font-weight: 540;
    color: $color-master-C7;
    text-align: center;
    margin: 40px auto 10px auto;
  }

  .line {
    position: absolute;
    bottom: 0px;
    z-index: -100;
    left: 0;
    border-radius: 3px;
    width: 78px;
    height: 8px;
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 88px;
    height: 60px;
  }

  .desc {
    text-align: center;
    margin-bottom: 24px;
    font-size: 12px;
    color: $color-master-C6;
    letter-spacing: 1px;
  }
}

.detailInfo {
  display: flex;
  margin-bottom: 15px;
  // justify-content: space-between;
  margin-left: 30px;
  margin-right: 32px;

  & > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    margin-right: 16px;
  }

  & > div:last-child {
    width: calc(100% - 42px);
    & > div:first-child {
      font-size: 14px;
      color: $color-master-C7;
    }

    & > div:last-child {
      font-size: 12px;
      color: $color-master-C5;
    }
  }
}

.bgBox {
  text-align: center;
  margin-bottom: 40px;
  & > img {
    width: 315px;
  }
}
