@import "../../../variables.module.scss";

.container {
  height: 100%;
  width: 100%;
  background-color: $color-white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.content {
  height: calc(100vh - 210px);
  border-radius: 0 10px 10px 10px;
}

.tabContent {
  height: 100%;
  padding: 15px;
  background-color: $color-master-C1;
  border-radius: 4px;
}

.tabTitle {
  font-size: 22px;
  color: $color-master-C7;
  display: flex;
  align-items: center;

  & > img {
    height: 32px;
    margin-right: 20px;
  }
}


.appLayoutBg {
  background-color: $color-white;
  border-radius: 4px;
  padding: 30px;
}