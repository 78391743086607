@import "../../variables.module.scss";

.container {
  height: 100%;
}

.row {
  display: flex;

  & > span {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.header {
  display: flex;
  align-items: center;
  background: rgba($color-white, 0.6);
  border-radius: 4px;
  padding: 8.5px 10px;
  height: 50px;
  font-size: 14px;
  color: $color-master-C5;
  border-right: 20px solid transparent;
}

.checkbox {
  width: 120px;
  flex: none !important;
  padding-left: 30px;
}

.itemContainer {
  height: calc(100% - 108px);
  overflow: auto;
  padding-bottom: 20px;
}

.item {
  background: $color-white;
  border-radius: 4px;
  padding: 7px 10px;
  margin-top: 10px;
  font-size: 16px;
  color: $color-master-C7;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  }
}

.itemChecked {
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.10) !important;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rowDetail {
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.10);

  & > div {
    background: $color-master-C1;
    border: 1px solid $color-master-C3;
    border-right: 8px solid $color-master-C4;
    border-radius: 8px;
    padding: 14px 30px;
    display: flex;
    font-size: 16px;
    color: $color-master-C6;
  }

  & > div:not(:last-child) {
    border-bottom: 1px solid $color-master-C3;
  }
}

.expendValue {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}
