@import "../../../../variables.module.scss";

.addBtn {
  background: $color-master-C1;
  border: 1px dashed $color-master-C4;
  border-radius: 2px;
  display: inline-block;
  padding: 2px 16px;
  cursor: pointer;
  color: $color-master-C7;

  &:hover {
    color: rgba($color: $color-master-C7, $alpha: 0.8);
  }
}

.modalContent {
  height: 420px;
}
