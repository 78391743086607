@import "../../../variables.module.scss";

.container {
  background-image: url("./images/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.banner {
  background-image: url("./images/1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 748px;
  margin-bottom: 20px;

  @media (max-width: 1399px) {
    height: 710.6px;
  }

  @media (max-width: 1365px) {
    height: 531.08px;
  }
}

.content {
  position: relative;
  background-image: url("./images/2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 760px;
  margin-bottom: 20px;

  @media (max-width: 1399px) {
    height: 722px;
  }

  @media (max-width: 1365px) {
    height: 539.6px;
  }

  .couponContent {
    position: absolute;
    top: 285px;
    left: calc((100vw - 550px) /2);
    text-align: center;

    @media (max-width: 1439px) {
      left: calc((100vw - 400px) /2);
    }
    @media (max-width: 1365px) {
      top: 190px;
    }
    
    & > p {
      margin-top: 10px;
    }
  }

  .btn {
    margin-top: 40px;

    @media (max-width: 1365px) {
      margin-top: 20px;
    }
  }

  .coupon {
    width: 550px;

    @media (max-width: 1439px) {
      width: 400px;
    }
  }
}

.record {
  position: relative;
  background-image: url("./images/3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 1016px;

  @media (max-width: 1399px) {
    height: 965.2px;
  }

  @media (max-width: 1365px) {
    height: 721.36px;
  }

  .recordContent {
    position: absolute;
    background: rgba(255,255,255,0.00);
    border: 5px solid $color-white;
    border-radius: 10px;
    width: 550px;
    height: 600px;
    top: 290px;
    left: calc((100vw - 550px) /2);
    overflow: auto;

    & > div:first-child {
      height: 100%;
    }

    @media (max-width: 1365px) {
      width: 500px;
      height: 450px;
      top: 200px;
      left: calc((100vw - 500px) /2);
    }
  }

  .noLogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    & > p {
      font-size: 16px;
      color: $color-master-C7;
      margin-bottom: 40px;
    }
  }
}

.qrCodeBg {
  position: relative;
  & > img {
    width: 430px;
  }


  .code {
    position: absolute;
    top: 240px;
    left: 125px;
  }

  .copyBtn {
    position: absolute;
    bottom: 85px;
    left: 125px;
  }
}

.recordList {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;

  & > div {
    text-align: center;
    margin-bottom: 20px;
  }

  .phoneNumber {
    font-size: 16px;
    color: $color-master-C7;
    margin-right: 95px;
  }

  .emphasize {
    font-size: 16px;
    color: $color-primary;
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  color: $color-master-C5;

  @media (max-width: 1366px) {
    font-size: 16px;
  }
}

.invitationImg {
  position: absolute;
  top: 100px;
  left: 40px;
}