@import "./variables.module.scss";

:global {

  // button
  .ant-btn {
    border-radius: 4px;
  }

  .ant-btn-ghost {
    border: 1px solid $color-primary;
    color: $color-primary;

    &:hover,
    &:focus {
      background: $color-primary-lightest;
      border: 1px solid $color-primary;
    }
  }

  .ant-btn-sm {
    font-size: 12px;
  }


  // .ant-input-prefix {
  //   margin-right: 8px !important;
  // }

  // form
  // .ant-form-item {
  //   margin-bottom: 32px;
  // }

  // .ant-form-item-with-help {
  //   margin-bottom: 0;
  // }

  // :where(.css-dev-only-do-not-override-wx0pjg).ant-form-item {
  //   margin-bottom: 32px;
  // }

  // .ant-form-item-explain, .ant-form-item-extra {
  //   min-height: 32px;
  // }

  .ant-btn-primary:focus {
    outline: none !important;
  }

  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-header {
    border-radius: 4px 4px 0 0;
  }

  .ant-popover-inner {
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.20) !important;
  }

  .ant-tooltip-inner {
    border-radius: 4px !important;
  }

  .ant-table-thead>tr>th {
    background: $color-master-C4 !important;
    border: none;
    padding: 9px 16px;
  }

  .ant-table-column-sorters {
    padding: 9px 16px;
  }

  .ant-table-tbody>tr>td {
    border: none;
  }

  td.ant-table-column-sort {
    background-color: unset;
  }

  .ant-table {
    background-color: unset;
  }

  .ant-table-row-expand-icon-cell {
    padding: 0 !important;
    cursor: pointer;
  }

  .ant-table-thead>tr>th {
    color: $color-master-C6;
    font-weight: 600;
  }

  .ant-message-notice-content {
    border-radius: 4px !important;
  }

  // .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
  //   border-top-right-radius: 0 !important;
  //   border-bottom-right-radius: 0 !important;
  // }

  .ant-dropdown-menu {
    padding: 0 2px !important;
  }
}

::-webkit-scrollbar {
  width: 15px;
  background: $color-master-C1;
  border: 1px solid $color-master-C3;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: $color-master-C4;
  border: 3px solid transparent;
  background-clip: content-box;

  &:hover {
    background: rgba($color-master-C5, 0.8);
    background-clip: content-box;
  }
}