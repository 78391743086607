@import '../../../variables.module.scss';

.banner {
  height: 50vw;
  margin-bottom: 60px;
  width: 100vw;

  .video {
    position: absolute;
    top: 25%;
    right: 41%;
  }

  .context {
    position: relative;
    padding-left: 15%;
    font-size: 14px;
    color: $color-master-C7;
    background: url('./images/banner.png') no-repeat bottom;
    background-size: 100% 100%;
    height: calc(50vw - 80px);

    @media (max-width: 1024px) {
      margin-top: 0;

      h1 {
        font-size: 20px !important;
        margin-bottom: 0 !important;
      }

      & > img {
        height: 35px;
      }
    }

    & > p {
      line-height: 22px;

      & > span {
        font-weight: bold;

        &:first-child {
          font-size: 16px;
          color: $color-primary;
        }
      }
    }

    .register {
      position: absolute;
      min-width: 375px;
      min-height: 200px;
      height: 272px;
      background: $color-white;
      box-shadow: 4px 6px 12px 0 rgba($color: $color-master-C7, $alpha: 0.1);
      border-radius: 10px;
      width: 375px;
      top: 15vw;
      right: 10%;
      padding: 21px 20px 10px 20px;

      & > .header {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;

        @media (max-width: 1366px) {
          margin-bottom: 20px;
        }

        & > span {
          padding: 0 10px;
          font-size: 18px;
          border-bottom: 5px solid #ffb602;
          line-height: 28px;
        }
      }

      .free {
        margin: 0 5px;
        font-size: 24px;
        color: #ffa400;
        font-weight: 600;
      }
    }
  }

  header {
    height: 80px;
    line-height: 80px;

    p {
      font-size: 14px;
      color: $color-master-C7;

      & > span {
        font-weight: 600;
      }

      & > span:first-child {
        font-size: 16px;
        color: $color-primary;
      }

      margin-left: 80px;
      height: 40px;
    }
  }
}

h3 {
  font-weight: bold;
  font-size: 38px;
  color: $color-master-C7;
  text-align: center;
}

.sem {
  width: 70%;
  margin: 0 auto;

  @media (max-width: 1440px) {
    width: 74%;
  }

  @media (max-width: 1024px) {
    width: 86%;
  }

  & > div:first-child {
    & > p {
      font-size: 20px;
      color: $color-master-C6;
      text-align: center;
    }
  }

  .advantage {
    display: flex;
    flex-wrap: wrap;
    margin: 80px 0 40px 0;

    img {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 15px;
    }

    & > div {
      display: flex;
      align-items: center;
      width: 50%;
      margin-bottom: 80px;

      img {
        margin: 0 30px 0 0;
        width: 44px;
      }

      p:last-child {
        font-size: 16px;
        color: $color-master-C6;

        @media (max-width: 1024px) {
          width: 70%;
        }
      }
    }

    div:nth-child(3n + 3) {
      width: 50%;
    }

    .title {
      font-size: 20px;
      color: $color-master-C7;
    }
  }
}

.functions {
  margin-bottom: 154px;
}

.featureContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 74%;
  flex-wrap: wrap;
  margin: 50px auto 120px auto;

  @media (max-width: 1640px) {
    width: 100%;
  }
}

.feature {
  position: relative;
  width: 564px;
  height: 170px;
  background: $color-white;
  box-shadow: 2px 2px 18px 0 rgba(21, 100, 71, 0.09);
  border-radius: 8px;
  margin: 0 30px 30px 0;
  display: flex;
  align-items: center;

  & > img {
    width: 120px;
    margin: 0 20px 0 22px;
  }

  & > div {
    color: $color-master-C7;
    .name {
      position: absolute;
      top: 52px;
      z-index: 1;
      font-size: 16px;
    }

    .border {
      position: absolute;
      height: 10px;
      top: 65px;
      border-radius: 5px;
      width: 280px;
      background-color: #e7ffec;
    }

    .title {
      position: absolute;
      top: 95px;
      font-weight: bold;
      font-size: 18px;
    }
  }
}

footer {
  background-color: $color-master-C1;
}

.footer {
  display: flex;
  height: 275px;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin: 0 auto;

  @media (max-width: 1440px) {
    width: 74%;
  }

  @media (max-width: 1024px) {
    width: 85%;
  }

  & > div {
    flex: 1 1 auto;
    font-size: 14px;
    color: $color-master-C7;
  }

  & > div:first-child {
    img {
      height: 40px;
      width: 187px;
      margin-bottom: 30px;
    }
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: $color-master-C6;

    p {
      margin-bottom: 5px;
    }

    img {
      width: 100px;
      height: 100px;
      margin-bottom: 17px;
    }
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 15px;
    }
  }
}

.fixedToBottom {
  position: fixed;
  height: 80px;
  bottom: -1px;
  width: 100%;
  background: $color-white;
  box-shadow: 2px -4px 8px 0 rgba($color: $color-master-C7, $alpha: 0.1);
  background: url('./images/bottom.svg') no-repeat left;

  display: flex;
  justify-content: space-around;
  padding: 23px 0;
  align-items: baseline;

  & > p {
    font-size: 16px;
    color: $color-master-C7;
  }

  .bottomFree {
    font-size: 18px;
    color: $color-function-warning;
    font-weight: 600;
  }

  @media (max-width: 1024px) {
    p {
      font-size: 14px;
      color: $color-master-C7;
    }

    .bottomFree {
      font-size: 16px;
    }
  }
}

.submit {
  height: 34px;
  background: $color-primary;
  border: 1px solid $color-primary;
  box-shadow: 2px 4px 12px 0 rgba(42, 194, 139, 0.2);
  padding: 4.4px 28px;
  font-size: 14px;

  &:hover,
  &:focus {
    background: $color-primary;
    box-shadow: 2px 4px 6px 0 rgba(42, 194, 139, 0.2);

    & > span {
      opacity: 0.8;
      color: $color-white;
    }
  }
}

.platformBox {
  display: inline-flex;
  align-items: center;
  margin-top: 32px;
  background: $color-white;
  border-radius: 20px;
  padding: 8px 15px;
  position: absolute;
  right: 10%;
  top: calc(15vw + 276px);
  .textStyle {
    margin-right: 20px;

    @media (max-width: 1024px) {
      margin-right: 10px;
    }
  }

  @media (max-width: 1600px) {
    margin-top: 12px;
  }

  @media (max-width: 1440px) {
    margin-top: 18px;
  }

  @media (max-width: 1366px) {
    margin-top: 12px;
  }

  @media (max-width: 1024px) {
    margin-top: 8px;
    font-size: 8px;
    padding: 2px 6px;

    img {
      width: 16px;
    }
  }

  img {
    margin: 0 5px;
  }
}

:global {
  #registerForBottom_smsCode {
    .ant-input {
      width: 202px;
    }
  }

  #registerForBottom_phone {
    width: 312px;
  }

  @media (max-width: 1024px) {
    #registerForBottom_phone {
      width: 202px;
    }
  }
}
