@import "../../../../variables.module.scss";

.tableContent {
  animation: show 0.5s ease;
  height: 100%;
}

@keyframes show {
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
}

.name {
  display: flex;
  align-items: center;
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  border: 2px solid $color-white;
  margin-right: 20px;
}

.available {
  font-size: 30px;
  color: $color-primary;
}

.forbidden {
  font-size: 30px;
  color: $color-function-error;
}

.restricted {
  font-size: 18px;
  color: $color-function-warning;
  display: flex;
  align-items: center;

  & > svg {
    font-size: 30px;
    margin-right: 15px;
  }
}

.text {
  max-width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  @media (max-width: 1440px) {
    max-width: 22vw;
  }

}

.fixationText {
  width: 5vw;
}

.searchInput {
  width: 400px;
}

.topContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.switchContainer {
  font-size: 16px;
  color: $color-master-C6;

  & > span:nth-of-type(1) {
    margin-right: 20px;
  }
}

.tableContent {
  position: relative;
}

.mask {
  position: absolute;
  opacity: 0.5;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: $color-master-C1;
  z-index: 99;
}