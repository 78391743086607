@import "../../../variables.module.scss";

.container {
  padding-bottom: 30px;
  position: relative;
}

.flexBox {
  display: flex;
  align-items: center;
}

.info {
  width: 85%;
  word-break: break-all;
  margin-left: 60px;
}

.time {
  font-size: 14px;
  color: $color-master-C6;
  margin-bottom: 10px;
}

.name {
  font-size: 16px;
  color: $color-master-C7;
}

.urlContainer {
  position: relative;
  margin-left: 72px;
}

.url {
  background: $color-master-C1;
  border-radius: 4px;
  font-size: 14px;
  color: $color-master-C7;
  padding: 7px 20px;
  margin-top: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  width: 41vw;

  @media (max-width: 1024px) {
    width: 38vw;
  }
}

.popover {
  position: absolute;
  width: 100%;
  word-break: break-all;
  background: rgba(51, 51, 51, 0.5);
  border-radius: 4px;
  color: $color-white;
  padding: 5px 10px;
  margin-top: 5px;
  bottom: 45px;
}

.line {
  position: absolute;
  top: 45px;
  left: 16px;
  border: 1px solid rgba(221, 221, 221, 0.2);
  height: calc(100% - 32px);
}


.icon {
  position: absolute;
  top: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 140% auto;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  border: 2px solid $color-white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
  margin-right: 20px;
}