@import "../../variables.module.scss";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color-black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modalBasiccontent {
  position: relative;
}

.showContent {
  animation: showContent 0.5s;
}

.hideContent {
  animation: hideContent 0.5s;
}

@keyframes showContent {
  from { transform: scale(0); opacity: 0 }
  to { transform: scale(1); opacity: 1 }
}

@keyframes hideContent {
  from { transform: scale(1); opacity: 1 }
  to { transform: scale(0); opacity: 0 }
}

.infoModalContent {
  background: $color-white;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.20);
  border-radius: 4px;
  width: 432px;
  padding: 32px 32px 24px 32px;
  font-size: 14px;
  color: $color-master-C6;
}

.infoModalText {
  display: flex;
  align-items: flex-start;

  & > svg {
    color: $color-primary;
    font-size: 22px;
    margin-right: 16px;
  }
}

.btnContainer {
  text-align: right;
  margin-top: 22px;

  & > button:nth-of-type(2) {
    margin-left: 10px;
  }
}

.confirmModalTitle {
  font-size: 16px;
  color: $color-master-C7;
  font-weight: 500;
  display: flex;
  align-items: flex-start;

  & > svg {
    color: $color-function-warning;
    font-size: 22px;
    margin-right: 16px;
  }
}

.desc {
  margin-top: 12px;
}

.normalContent {
  min-width: 600px;
  max-width: 800px;
  background: $color-white;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.20);
  border-radius: 4px; 
}

.normalTitle {
  padding: 16px 24px;
  border-bottom: 1px solid $color-master-C3;
  font-size: 16px;
  color: $color-master-C7;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg {
    color: rgba(0,0,0,0.45);
    cursor: pointer;
  }
}

.normalInfo {
  padding: 24px;
  max-height: 468px;
  overflow: auto;
}

.normalBtnContainer {
  text-align: right;
  padding: 15px 24px;
  border-top: 1px solid $color-master-C3;

  & > button:nth-of-type(2) {
    margin-left: 10px;
  }
}

.specialContent {
  background: $color-white;
  box-shadow: 0 4px 12px 0 rgba(0,0,0,0.20);
  border-radius: 4px;
  padding: 32px 30px;
}

.closeIconContainer {
  position: relative;

  & > img {
    position: absolute;
    right: -30px;
    top: -25px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.specialTitle {
  text-align: center;
  font-size: 24px;
  color: #3a3a3a;
  font-weight: 600;
}

.specialInfo {
  margin-top: 24px;
}

.specialBtnContainer {
  margin-bottom: 24px;

  & > div {
    margin: 0 auto;
  }
}