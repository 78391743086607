@import "../../../variables.module.scss";

.outerContainer {
  background-color: $color-master-C1;
}

.container {
  z-index: 99;
  padding: 20px 0;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px solid $color-master-C4;
}

.subPath {
  .title {
    font-size: 18px;
    color: $color-master-C7;
  }

  .desc {
    font-size: 14px;
    color: $color-master-C6;
  }
}

.popOver {
  & > div > div:nth-of-type(1) {
    border-top-color: $color-master-C1 !important;
    border-left-color: $color-master-C1 !important;
  }

  & > div > div:nth-of-type(2) {
    background: $color-white;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
    padding: 5px;
    border-radius: 4px;
  }

  &>div>div>div {
    padding: 0 !important;
  }
}

.popContainer {
  width: 192px;
}

.popContent {
  padding: 10px 20px;

  &:hover {
    background: $color-master-C1;
  }
}

.logout {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $color-master-C6;
  text-align: left;
  cursor: pointer;

  & > img {
    margin-right: 10px;
  }
}

.popHeader {
  background: $color-white;
  box-shadow: 2px 3px 5px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.accountInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accountName {
  font-size: 14px;
  color: $color-master-C7;
  font-weight: 600;

  &>span {
    margin-right: 5px;
  }
}

.accountTime {
  border-radius: 13px;
  background: rgba(42, 194, 139, 0.2);
  padding: 3px 15px;
  font-size: 12px;
  color: $color-master-C6;
  margin-bottom: 10px;

  & > span > span {
    color: $color-master-C7;
    margin: 0 2px;
  }
}

.purchase {
  font-size: 12px;
  color: $color-primary;
  cursor: pointer;
  text-decoration: underline;
}

.popFooter {
  padding: 15px 30px;
  display: flex;
  justify-content: space-around;

  span {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    color: $color-master-C6;

    & > img {
      margin-bottom: 5px;
    }
  }
}

.homeLink {
  & img {
    height: 50px;
  }
}

.accountContent {
  display: flex;
  align-items: center;
  cursor: pointer;
}