@import "../../../../variables.module.scss";

.container {
  flex: 6;
  padding: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  color: $color-master-C7;
  font-weight: 500;
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-height: 768px) {
    font-size: 22px;
  }
}

.deviceInfoEmpty {
  display: flex;
  position: relative;
  justify-content:center;

  & > div:nth-of-type(1) {
    opacity: 0.6;
    width: 100%;
    display: flex;
  }

  & > div:nth-of-type(2) {
    position: absolute;
    top: -12px;
    z-index: 99;
  }
}

.functionContainer {
  border-top:  1px solid $color-master-C4;
  margin-top: 30px;
  padding-top: 30px;
  display: flex;
  gap: 20px;
}

.functionCard {
  background: $color-white;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.08);
  border-radius: 4px;
  width: calc((100% - 60px)/4);
  padding-top: 20px;
  text-align: center;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-5px);

    .setting {
      background: rgba(26,185,125,0.08);
    }

    .setted {
      background: $color-master-C2;
    }
  }
}

.setting {
  background: rgba(30,211,143,0.08);
  padding: 9px 0;
  font-size: 14px;
  color: $color-primary;
  cursor: pointer;
}

.setted {
  background: $color-master-C1;
  font-size: 14px;
  color: $color-master-C6;
  padding: 9px 0;
  cursor: pointer;
}

.functionName {
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 16px;
  color: $color-master-C7;

  @media (max-height: 768px) {
    font-size: 14px;
  }
}


.deviceInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 14px;
  color: $color-master-C5;

  & > div {
    width: calc(50% - 10px);

    & > span:nth-of-type(2) {
      font-size: 14px;
      color: $color-master-C7;
    }
  }
}

.unbind {
  font-size: 12px;
  // color: $color-master-C5;
  display: inline-flex;
  align-items: center;

  & > img {
    margin-right: 5px
  };
}

.deviceName {
  display: flex;
  align-items: center;
  position: relative;

  & > div {
    margin-left: 15px;
  }
}

.deviceStatus {
  position: absolute;
  bottom: -28px;
  left: 0;
}

.pcHideMode {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: $color-master-C7;
  font-weight: normal;

  @media (max-height: 768px) {
    font-size: 12px;
  }

  & > button {
    margin-left: 20px;
  }
}

.modalContainer {
  background: $color-white;
  border-radius: 10px;
  width: 620px;
  padding: 40px;

  & img {
    width: 100%;
  }
}

.modalTitle {
  text-align: center;
  font-size: 24px;
  color: $color-master-C7;
  margin-bottom: 30px;
}

.modalBtn {
  width: 380px;
  margin: 40px auto 0;
}

.lockScreen {
  font-size: 14px;
  color: $color-function-error !important;
}

.behaviorContainer {
  flex-grow: 1;
}
:global(.ant-tooltip){
  max-width: none;
}