@import "../../../variables.module.scss";

.container {
  padding: 10px 0;
  background: $color-master-C1;

  span:nth-of-type(1) {
    margin: 0 10px;
    font-size: 16px;
    color: $color-master-C4;
  }
}

.mainLink{
  background: $color-primary;
  box-shadow: 4px 8px 12px 0 rgba(42,194,139,0.20);
  color: $color-white !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &:hover {
    background: $color-primary-dark;
    box-shadow: 4px 8px 12px 0 rgba(42,194,139,0.20);
  }
}

.currentLink {
  display: inline-block;
  padding: 8px 20px;
  background: $color-white !important;
  border-radius: 20px;
  font-size: 16px;
  color: $color-master-C6 !important;
}
