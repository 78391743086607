@import "../../../variables.module.scss";

h1 {
  font-size: 20px;
  color: $color-master-C7;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 30px;
}

.container {
  position: relative;
  background-image: url("images/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  background-attachment: fixed;
  min-height: 100vh;
  padding-top: 20vh;
}

.content {
  width: 432px;
  margin: 0 auto 0;
  padding: 40px 40px 60px;
  background: $color-white;
  border: 1px solid $color-master-C4;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.20);
  border-radius: 10px;
}

.header {
  position: absolute;
  top: 0;
  width: 100%;
}

.logoContainer {
  width: 90%;
  margin: 0 auto;
  padding: 25px 0;
}

.logo {
  height: 40px;
}
