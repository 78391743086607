@import "../../../variables.module.scss";

.bindWindowsCard {
  background: $color-white;
  border-radius: 4px;
  padding: 40px;

  .bindWindowsCardTitle {
    font-size: 20px;
    color: $color-master-C7;
    text-align: center;
    margin-bottom: 40px;
  }

  .bindWindowsCardDesc {
    margin: 40px 0;
    text-align: center;
  }
}
