@import "../../../variables.module.scss";

.container {
  background: $color-white;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
}

.content {
  background: $color-master-C1;
  border-radius: 10px;
  height: 100%;
  padding-top: 20px;
}

.switch {
  text-align: right;
  padding: 0 40px 0 0;
}

.table {
  padding: 20px 10px 0 10px;
  height: calc(100% - 19px);
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 140% auto;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  border: 2px solid $color-white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
  margin-right: 20px;
}

.name {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: $color-master-C6;
  width: 100%;
}

.nameValue {
  display: inline-block;
  width: 450px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1440px) {
    width: 300px;
  }

  @media (min-width: 1800px) {
    width: 40vw;
  }
}

.recordDetail {
  font-size: 14px;
  color: $color-master-C5;

  & > span {
    color: $color-primary;
    margin-left: 2px;
    cursor: pointer;

    & > img {
      margin-left: 5px;
    }
  }
}
