@import "../../../../variables.module.scss";

.root {
  display: flex;
  flex-direction: column;
  padding: 40px 40px 34px 40px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
