@import '../../variables.module.scss';

.container {
  display: flex;
  align-items: center;
  width: 100%;
}

.label {
  font-size: 14px;
  color: $color-master-C7;
  margin-right: 20px;
}

.input {
  position: relative;
  flex-grow: 1;

  & > span > div:nth-of-type(1):hover {
    border: 1px dashed $color-primary;
  }
}

.error {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: $color-function-error;
  font-size: 12px;
}

.inputError {
  & > input {
    border: 1px solid $color-function-error;

    &:hover {
      border: 1px solid $color-function-error;
    }
  }

  & > input:focus {
    border: 1px solid $color-function-error;
    box-shadow: 0 0 0 2px rgba(255, 38, 5, 0.06);
  }
}

.searchIcon {
  display: flex;
  align-items: center;
  border-left: 1px solid $color-master-C4;
  padding-left: 10px;
  cursor: pointer;
}

:global {
  .ant-input {
    border-radius: 4px !important;
    padding: 5px 11px;

    &:focus {
      box-shadow: 0 0 8px 0 rgba(30,211,143,0.30);
    }
  }
  .ant-input-lg {
    border-radius: 4px !important;
    font-size: 14px !important;
    padding: 8.5px 11px;

    &:focus {
      box-shadow: 0 0 8px 0 rgba(30,211,143,0.30);
    }
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 8px 0 rgba(30,211,143,0.30);
  }

  .ant-input-affix-wrapper {
    padding: 5px 11px;
  }

  .ant-input-affix-wrapper-lg {
    padding: 8.5px 11px;
    font-size: 14px;
    border-radius: 4px
  }
}