@import "../../../../variables.module.scss";

.dataPanel {
  & > div:last-child > div:first-child > div:first-child {
    display: none;
  }
}

.date {
  font-size: 16px;
  color: $color-master-C7;
  margin-bottom: 20px;
}

.empty {
  text-align: center;
  & > img {
    margin: 100px auto 30px auto;
  }
}

.text {
  font-size: 14px;
  color: $color-master-C6;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  float: right;
  height: calc(100vh - 110px);
  width: 300px;
  background: $color-white;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.08);
  border-radius: 20px 0 0 0;
}

.xdBehaviorContainer {
  position: relative;
  height: 400px;
  flex: 1;
  padding: 20px;
  overflow: hidden;
  border-radius: 0 0 0 20px;
}

.maskContent {
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 70%;
    background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 0%, $color-white 100%);
    bottom: 0;
    left: 0;
  }
}

.title {
  font-size: 18px;
  color: $color-master-C7;
}

.content {
  padding: 30px 0 20px;
}

.onMask {
  position: absolute;
  bottom: 25px;
  left: 90px;
  z-index: 90;
}

.record {
  overflow: hidden;
}

.btn {
  margin: 0 auto;
  position: absolute;
  bottom: 25px;
  right: 95px;
}

.behaviorContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  background: rgba(248,248,248,0.50);
  border-radius: 4px;
  margin-top: 30px;
  padding: 20px;
  height: calc(100% - 20px);
}

.behaviorTitle {
  font-size: 18px;
  color: $color-master-C7;

  @media (max-height: 768px) {
    font-size: 16px;
  }
}

.behaviorEmpty {
  height: 100%;
  font-size: 14px;
  color: $color-master-C5;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    margin-right: 10px;
  }
}

.behaviorContent {
  height: 5vh;
  margin-top: 20px;
  overflow: hidden;
  flex-grow: 1;
}

.behaviorMask {
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(180deg, rgba(251,251,251,0.00) 0%, #FBFBFB 100%);
  width: calc(100% - 20px);
  font-size: 14px;
  color: $color-primary;
  display: flex;
  justify-content: center;   
  padding: 58px 0 10px;

  & img {
    margin-left: 5px;
  }
}

.behaviorLink {
  color: $color-primary;

  &:hover {
    color: $color-primary-dark;
  }
}