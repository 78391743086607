@import "../../../../variables.module.scss";

.workOrder {
  text-align: right;
  font-size: 14px;
  color: $color-master-C6;

  & > span {
    display: inline-flex;
    align-items: center;
  
    & img {
      margin-right: 5px;
    }
  }
}

.addText {
  font-size: 14px;
  color: $color-primary;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.searchInput {
  border-radius: 4px;
}

.websiteContainer {
  background: $color-master-C1;
  border-radius: 4px;
  height: 400px;
  overflow: auto;
  padding: 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.websiteTitle {
  font-size: 14px;
  color: $color-master-C7;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  & > img {
    margin-left: 8px;
  }
}

.websites {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 10px;
}

.websiteItem {
  position: relative;
  display: inline;
  font-size: 12px;
  color: $color-master-C6;
  border: 1px solid $color-master-C3;
  border-radius: 2px;
  background: $color-white;
  padding: 4px 16px;
  cursor: pointer;

  &:hover {
    background: $color-master-C1;
  }

  & > img {
    position: absolute;
    bottom: -1px;
    right: 0;
  }
}

.websiteItemSelected {
  font-size: 12px;
  color: $color-primary;
  border: 1px solid $color-primary;

  &:hover {
    background: rgba(30,211,143,0.05);
  }
}

.searchEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: $color-master-C5;
  margin-top: 14vh;

  & > img {
    margin-right: 5px;
  }
}

.searchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  & > span {
    width: 279px;
  }
}

.showAllBtn {
  display: inline-flex;
  align-items: center;
  background: $color-white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20);
  border-radius: 4px;
  font-size: 14px;
  color: $color-master-C7;
  padding: 7px 16px;
  cursor: pointer;
  margin-bottom: 30px !important;

  & > img {
    margin-right: 5px;
  }

  &:hover {
    background: $color-master-C1;
  }
}

.itemsContainer {
  & > div {
    margin-bottom: 20px;
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 38px auto 20px auto;

  & > div {
    width: 350px;
  }

  & > div:nth-of-type(1) {
    margin-right: 20px;
  }
}

.disableContent {
  opacity: 0.5;
}

.mask {
  position: absolute;
  background-color: $color-white;
  width: calc(100% - 60px);
  height: calc(100% + 5px);
  z-index: 99;
  opacity: 0.5;
}

.deleteIcon {
  display: inline-block;
  text-align: center;
  line-height: 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: rgba(153,153,153,0.10);
  margin-left: 5px;
  font-size: 10px;

  &:hover {
    opacity: 0.8;
  }
}

.deleteIconSelected {
  background: rgba(30,211,143,0.10);
}

.addBtn {
  background: $color-master-C1;
  border: 1px dashed $color-master-C4;
  border-radius: 2px;
  display: inline-block;
  padding: 2px 16px;
  cursor: pointer;
  color: $color-master-C7;

  &:hover {
    color: rgba($color: $color-master-C7, $alpha: 0.8);
  }
}
