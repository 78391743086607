@import "../../../variables.module.scss";

.container {
  background-color: $color-white;
  border-radius: 10px;
  width: 520px;
}

.header {
  border-bottom: 1px solid $color-master-C4;
  padding: 16px 24px;
  span {
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
}

.infoCard {
  background: $color-white;
  border-radius: 10px;
  padding: 20px 20px 30px 20px;
  margin-bottom: 20px;
}

.title {
  font-size: 16px;
  color: $color-master-C7;
  font-weight: 500;
  padding-bottom: 20px;
  border-bottom: 1px solid $color-master-C4;
  margin-bottom: 20px;
}

.detail {
  & > div {
    display: flex;
    align-items: center;
    margin-top: 30px;
    color: $color-master-C7;
  }
}

.itemTitle {
  width: 118px;
  display: inline-block;
  color: $color-master-C6;
}

.btnContainer {
  margin-top: 60px;
}

.purchaseBtn {
  margin-right: 15px;
}

.modifyBtn {
  margin-left: 20px;

  & > button > span {
    font-size: 12px;
  }
}

.modalContent {
  & > form > div:nth-of-type(1) {
    padding: 24px !important;
  }

  & > form > div:nth-of-type(2) {
    margin: 0 !important;
  }
}

.formBtn {
  text-align: right;
  padding: 15px 24px;
  border-top: 1px solid $color-master-C3;

  & > button:nth-of-type(1) {
    margin-right: 15px;
  }
}

.limitInfo {
  display: flex;
  align-items: center;
  color: $color-master-C7;

  & button {
    font-size: 12px;
    margin-left: 20px;
  }
}

.expired {
  color: $color-master-C5;
  opacity: 0.6;
}

.activationCode {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.inputContainer {
  position: relative;
  width: 252px;
  margin-right: 15px;

  & > span {
    position: absolute;
    left: 0;
    bottom: -25px;
    font-size: 13px;
    color: $color-function-error;
  }
}

.inputError {
  border: 1px solid $color-function-error;
  box-shadow: 0 0 6px 0 rgba(247,94,38,0.30);
}
