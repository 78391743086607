@import "../../../variables.module.scss";

.container {
  position: relative;
  background: $color-white;
  box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.10);
  border-radius: 8.4px;
  width: 100%;
  text-align: center;
  padding-top: 50px;

  &>div:first-child {
    height: 240px;
  }

  .recommendTag {
    position: absolute;
    top: 0;
    right: 0;
  }

  .anniversaryTag {
    position: absolute;
    top: 15px;
    left: -10px;
    width: 190px;
  }

  .anniverasyTips {
    position: absolute;
    top: 19px;
    left: 145px;
  }
}

.title {
  font-size: 20px;
  color: $color-master-C7;
  font-weight: bold;
}

.desc {
  font-size: 14px;
  color: $color-master-C6;
}

.currentPrice {
  font-weight: bold;
  font-size: 40px;
  color: $color-master-C7;
  margin-top: 30px;
}

.prePrice {
  font-size: 16px;
  color: $color-master-C5;
  text-decoration: line-through;
}

.horizontalDividingLine {
  display: inline-block;
  background: $color-primary;
  border-radius: 4px;
  width: 33px;
  height: 4px;
  margin: 10px 0 15px 0;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 111px;
  border-top: 1px solid $color-master-C4;

}

.priceGroup {
  margin-bottom: 30px;
}