@import "../../variables.module.scss";

.tooltip
{
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  
  display: inline-block;
}

.tooltip.top
{
  margin-top: -30px;
}

.tooltip .tooltipArrow
{
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.top .tooltipArrow
{
  top: auto;
  bottom: -5px;
  left: 13px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip .tooltipArrow
{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

.tooltip .tooltipLabel
{
  max-width: 200px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}