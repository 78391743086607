@import '../../../variables.module.scss';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
  padding-inline-start: 5px;

  & > div {
    height: 80px;

    &:not(:first-child) {
      li {
        margin-left: -30px;
      }
    }
  }

  li {
    position: relative;
    width: 260px;
    height: 80px;
    z-index: 20;
    padding: 15px 55px 0 15px;
    display: inline-block;
    margin-left: -18px;
    opacity: 0.6;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: -30px;
    }

    &:hover {
      opacity: 1;
      &::after {
        top: 0px;
      }
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      top: 4px;
      border-radius: 10px 10px 0 0;
      height: 10px;
      width: 70%;
      margin-left: 10%;
      z-index: -5;
      transition: all 0.3s;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 5px;
      left: 0;
      background-color: $color-white;
      border-radius: 10px 10px 0 0;
      transform: perspective(30px) scale(1, 1) rotateX(3deg);
      transform-origin: bottom left;
      border: 1px solid $color-master-C1;
      box-shadow: 4px -3px 5px 0px rgba(0, 0, 0, 0.1);
      z-index: -1;
      border-bottom: none;
      width: 90%;
      margin: 0 auto;
    }
  }
}

.containerYellow {
  li {
    &::after {
      background-color: $color-function-warning;
    }
  }
}

.containerBlue {
  li {
    &::after {
      background-color: $color-function-info;
    }
  }
}

.tabActive {
  z-index: 30 !important;
  opacity: 1 !important;
  &::after {
    top: 0px !important;
  }

  &::before {
    background-color: $color-master-C1 !important;
    bottom: 0 !important;
  }
}

.tabActive + li {
  &::before {
    z-index: -4 !important;
  }
}

.tabContent {
  z-index: 10;
  height: 70px;
  bottom: 5px;
  position: absolute;
  width: 70%;
  margin-left: 3%;
}

.activeContent {
  display: block;
}

.hiddenContent {
  display: none;
}

.content {
  border: 1px solid $color-master-C1;
  height: calc(100% - 60px);
  background-color: $color-master-C1;
  box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.1);
}
