@import "../../../variables.module.scss";

.content {
  background-color: $color-master-C1;
  min-height: calc(100vh - 60px - 256px);
  padding-bottom: 50px;
}

.container {
  padding-top: 100px;
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;

  @media (max-width: 1024px) {
    width: 85%;
  }
}

.nav {
  background: $color-white;
  border-radius: 8px;
  margin-right: 20px;
  padding-bottom: 20px;

  & > img {
    width: 320px;
  }
}

.info {
  background: $color-white;
  border-radius: 8px;
  flex-grow: 1;
  padding: 40px;
}

.titleItem {
  padding: 9px 20px 9px 40px;
  font-size: 16px;
  color: $color-master-C7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;

  & > svg {
    color: $color-master-C4;
  }
}

.activeTitle {
  background: $color-primary;
  color: $color-white !important;
}

.normaQuestionItem {
  background-color: rgba(248, 248, 248, 0.5);

  &:hover {
    color: $color-primary;
  }
}

.normalTitle {
  background: $color-white;
}

.search {
  width: 446px;
}

.detail {
  margin-top: 40px;
}

.hotQuestion {
  font-size: 16px;
  color: $color-master-C7;
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}

.questionItem {
  font-size: 14px;
  color: $color-master-C6;
  padding: 10px 40px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;

  & > span {
    display: inline-block;
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.label {
  font-size: 20px;
  color: $color-master-C7;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
}

.contentTitle {
  font-size: 16px;
  color: $color-master-C7;
  margin-bottom: 9px;
}

.contentInfo {
  font-size: 16px;
  color: $color-master-C6;

  & img {
    display: block;
    margin: 40px auto;
  }
}

.searchContainer {
  background-color: $color-white;
  width: 100%;
  border-radius: 8px;
}

.searchTitle {
  display: flex;
  align-items: center;

  & > img {
    margin-right: 60px;
  }
}

.searchContent {
  padding: 40px 80px;
}

.searchEmpty {
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: $color-master-C5;
}

.back {
  color: $color-primary;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    color: $color-primary-dark;
  }
}

.searchItem {
  display: flex;
  margin-bottom: 50px;
  cursor: pointer;

  & > img {
    align-self: baseline;
    margin-right: 30px;
  }
}

.searchTitle {
  font-size: 16px;
  color: $color-master-C7;
  font-weight: 600;
  margin-bottom: 10px;

  & > img {
    width: 320px;
  }
}

.searchAnswer {
  font-size: 16px;
  color: $color-primary;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
  word-break: break-all;

  &:hover {
    color: $color-primary-dark;
  }
}
