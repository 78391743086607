@import "../../variables.module.scss";

.qsTag {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 12px;
  line-height: 22px;

  & > img {
    margin-right: 5px;
  }
}

.qsTagError {
  background: rgba(247, 94, 38, 0.1);
  border: 1px solid $color-function-error;
  color: $color-function-error;
}

.qsTagWarn {
  background: rgba(255,167,0,0.06);
  border: 1px solid $color-function-warning;
  color: $color-function-warning;

  & > span:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 833px;
    white-space: nowrap;

    @media (max-width: 1600px) {
      max-width: 610px;
    }

    @media (max-width: 1440px) {
      max-width: 550px;
    }

    @media (max-width: 1366px) {
      max-width: 600px;
    }

    @media (max-width: 1024px) {
      max-width: 380px;
    }
  }
}

.qsTagSuccess {
  color: $color-primary;
  background: rgba(30, 211, 143, 0.1);
  border: 1px solid $color-primary;
}

.qsTagWithClick {
  display: inline-flex;
  align-items: center;
  background: $color-white;
  border: 1px solid $color-primary;
  border-radius: 2px;
  padding: 2px 10px 2px 16px;
  font-size: 14px;
  color: $color-primary;
  cursor: pointer;

  & > span {
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &:hover {
      background: rgba(30,211,143,0.10);
    }

    & > span {
      font-size: 10px;
    }
  }
}
