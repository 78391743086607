@import "../../variables.module.scss";

.container {
  text-align: center;
  animation: passIn 3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes passIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.desc {
  & > span {
    margin-left: 5px;
    font-size: 14px;
    color: $color-master-C5;
  }
}
