@import "../../../variables.module.scss";

.container {
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    width: calc(100% - 200px);
  }
}

.content {
  position: relative;
  width: 500px;
  text-align: center;
  background: $color-white;
  border-radius: 10px;
  padding: 30px 0;
  font-size: 14px;
  color: $color-master-C6;

  & > div {
    margin: 15px 0 30px;
  }

  & svg {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    color: $color-master-C5;
    cursor: pointer;
  }
}

:global {
  .ant-modal-body {
    padding: 0;
  }
}
