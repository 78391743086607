@import "../../../variables.module.scss";

.container {
  background: $color-white;
  border-radius: 10px;
  height: 100%;
  padding: 30px;
  overflow: auto;
}

.title {
  font-size: 16px;
  color: $color-master-C7;
  margin-bottom: 10px;
}

.header {
  width: 60%;
  border: 1px solid $color-master-C4;
  border-radius: 4px;
  margin-bottom: 40px;
  display: flex;

  @media (max-width: 1024px) {
    width: 78%;
  }

  & > span {
    flex: 1;
    padding: 8px 30px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    color: $color-master-C7;

    &:hover {
      background: $color-master-C1;
      border-radius: 4px;
    }
  }
}

.weekSelected {
  background: $color-primary;
  box-shadow: 0 2px 4px 0 rgba(30, 211, 143, 0.2);
  border-radius: 4px;
  color: $color-white !important;

  &:hover {
    background: $color-primary !important;
    box-shadow: 0 2px 4px 0 rgba(30, 211, 143, 0.2);
    border-radius: 4px;
    color: $color-white !important;
  }
}

.tips {
  font-size: 14px;
  color: $color-master-C6;
  margin-bottom: 40px;
}

.btnContainer {
  position: fixed;
  z-index: 100;
  left: 240px;
  right: 0;
  bottom: 60px;
  width: 450px;
  margin: 0 auto;

  @media (max-width: 1366px) {
    left: 50px;
  }
}

.timeContainer {
  position: relative;
  padding-bottom: 80px;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: calc(100% + 22px);
  height: 100%;
  background-color: rgba($color: $color-white, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    background: rgba($color: $color-master-C7, $alpha: 0.6);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-size: 14px;
    color: $color-white;
    font-weight: 500;
    padding: 10px 18px;
  }
}

.lockIcon {
  display: inline-flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-white;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.tips {
  font-size: 14px;
  color: $color-master-C5;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.desc {
  font-size: 14px;
  color: $color-master-C7;

  & > span:nth-of-type(1) {
    margin-right: 30px;
    & > span {
      background: rgba($color: $color-function-warning, $alpha: 0.6) !important;
    }
  }

  & > span {
    display: inline-flex;
    align-items: center;

    & > span {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      background: $color-master-C2;
      margin-right: 10px;
    }
  }
}

.amContainer {
  margin-bottom: 80px;
}

.unavailable {
  display: flex;
  margin-top: 50px;
  font-size: 14px;
  color: $color-master-C6;
  margin-bottom: 90px;

  & > span {
    flex: 1;
    margin-top: 4px;
  }

  & > div {
    flex: 12;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;

    @media (max-width: 1600px) {
      flex: 10;
    }
    @media (max-width: 1024px) {
      flex: 7;
    }
  }
}

.unavailableItem {
  background: rgba(255,167,0,0.06);
  border: 1px solid $color-function-warning;
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 14px;
  color: $color-function-warning;
  font-weight: 500;
}
