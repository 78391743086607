@import "../../../variables.module.scss";

h1,
.description {
  text-align: center;
}

.button {
  border: 1px solid $color-master-C7;
  color: $color-master-C7;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  width: 120px;
  height: 42px;
  font-size: 14px;
  letter-spacing: 1px;
  transition: all ease 0.2s;
  margin: 0px 5px;
}

.button:hover {
  background: $color-master-C7;
  color: $color-white;
}

#selecto2 .cube {
  transition: all ease 0.2s;
}

.moveable #selecto2 .cube {
  transition: none;
}

.selecto-area-yellow .selected {
  background: rgba($color: $color-function-warning, $alpha: 0.6);
}

.selecto-area-yellow .selected:hover {
  background: rgba($color: $color-function-warning, $alpha: 0.6);
}

.selecto-area-green .selected {
  background: $color-primary;
}

.selecto-area-green .selected:hover {
  background: $color-primary;
}

.scroll {
  overflow: auto;
  padding-top: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.infinite-viewer,
.scroll {
  width: 100%;
  height: 300px;
  box-sizing: border-box;
}

.infinite-viewer .viewport {
  padding-top: 10px;
}

.empty.elements {
  border: none;
}
