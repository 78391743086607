@import "../../../variables.module.scss";

.loginBtn {
  background: $color-primary !important;
  box-shadow: 2px 4px 8px 0 rgba(42, 194, 139, 0.30) !important;
  border-radius: 10px !important;
  height: 60px !important;
  font-size: 18px !important;
  color: $color-white !important;
  margin-top: 20px;
}

.tips {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin-bottom: 40px;
}