@import "../../../variables.module.scss";

.container {
  display: flex;
  height: 100vh;
}

.content {
  position: relative;
  flex-grow: 1;
}

.info {
  background-color: $color-master-C1;
  padding: 20px;
  height: calc(100vh - 70px);
}
