@import "../../../variables.module.scss";


.unexpired {
  background-image: url("images/memberCardBg.svg");
}

.expired {
  background-image: url("images/expiredCardBg.svg");
}

.memberCard {
  background-repeat: round;
  position: relative;
  height: 152px;
  width: 200px;
  padding: 20px;
  text-align: center;
}

.memberCardIcon {
  position: absolute;
  top: -39px;
  left: 57px;
}

.memberCardTitle {
  font-size: 16px;
  color: $color-master-C6;
  font-weight: 600;
  margin: 15px 0 10px;
}

.purchaseBtn {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  & > span {
    margin-right: 8px;
  }

  &:hover {
    opacity: 0.8;
  }
}


.unexpiredBtn {
  color: $color-function-warning;
}

.expiredBtn {
  color: $color-function-info;
}

.memberTime {
  margin-right: 5px;
}
