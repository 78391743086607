@import "../../../../variables.module.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.title {
  font-size: 20px;
  color: $color-master-C7;
  text-align: center;
  margin-top: 60px;
}

.desc {
  font-size: 14px;
  color: $color-master-C5;
  margin: 10px auto 40px auto;
}

.yellowStress {
  color: $color-function-warning;
}

.blueStress {
  color: $color-function-info;
}

.descContainer {
  margin: 40px auto;
  text-align: center;
}

.header {
  display: block;
  font-size: 24px;
  color: $color-master-C7;
  margin-bottom: 10px;
}

.errorTips {
  display: block;
  font-size: 16px;
  color: $color-master-C6;
}
