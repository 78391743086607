@import "../../../variables.module.scss";

.container {
  width: 100%;
  display: flex;
  height: 100%;
  background-color: $color-white;
  overflow: auto;
}

.unbindPcStepPage {
  padding: 30px;
  width: 100%;
}

.unbindPcBack {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $color-master-C7;

  & > span {
    font-size: 16px;
    margin-left: 15px;
  }

  &:hover {
    color: rgba($color-master-C7, 0.8);
  }
}

.unbindPcStepList {
  width: 486px;
  margin: 0 auto;
  padding: 60px 0;
}

.unbindPcStepTitle {
  & > span:first-child {
    font-size: 18px;
    color: $color-primary;
    text-align: left;
    margin-right: 20px;
  }

  & > span:last-child {
    font-size: 18px;
    color: $color-master-C7;
    text-align: left;
  }
}

.unbindPcStepDesc {
  margin: 50px 0;
}
