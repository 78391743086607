@import "../../../variables.module.scss";

.container {
  position: relative;
  display: flex;
  justify-content: space-between;

  & > div {
    position: relative;
    flex: 1;
    border-radius: 4px;
    padding: 30px 0 27px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

  }

  & > div:not(:last-child) {
    margin-right: 20px;
  }
}

.checkIcon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
}

.price {
  position: relative;
  font-size: 40px;
  color: $color-master-C7;
  font-weight: 600;

  &::before {
    content: '¥';
    position: absolute;
    left: -13px;
    top: 5px;
    font-size: 16px;
    color: $color-master-C7;
    font-weight: normal;
  }
}

.originPrice {
  font-size: 12px;
  color: $color-master-C5;
  text-decoration: line-through;
}

.checked {
  position: relative;
  background: rgba(30,211,143,0.05);
  border: 1px solid $color-primary;
}

.normal {
  position: relative;
  color: $color-master-C7;
  background: $color-white;
  border: 1px solid $color-master-C4;

  &:hover {
    background: rgba(30,211,143,0.05);
    border: 1px solid $color-primary;
  }
}

.name {
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.originPriceContainer {
  height: 17px;
}

.discountInfo {
  position: absolute;
  top: 5px;
  right: -35px;
  background: $color-function-warning;
  border: 1px solid $color-function-warning;
  border-radius: 3px;
  font-size: 12px;
  color: $color-white;
  padding: 0 5px;
  font-weight: 500;
  margin-left: 5px;
}

.groupPurchaseDesc {
  display: inline-block;
  // width: 14px;
  height: 14px;
  margin-left: 2px;

  & > img {
    width: 60%;
  }
}

.anniversaryTag {
  position: absolute;
  top: 5px;
  left: -9px;
  width: 165px;
}

.anniversaryTips {
  position: absolute;
  top: 8px;
  left: 120px;
}
