@import "../../../../variables.module.scss";

.container {
  flex: 4;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

.top {
  position: relative;
  flex: 8;
  border-bottom: 1px solid $color-master-C4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottom {
  flex: 2;
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  justify-content: space-between;
  font-size: 18px;
  color: $color-master-C7;
  align-items: center;
  margin-top: 16px;

  @media (max-height: 768px) {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.switchDevice {
  position: absolute;
  bottom: -18px;
  background: $color-white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  font-size: 14px;
  color: $color-master-C7;
  padding: 7px 20px;
  cursor: pointer;

  &:hover {
    background: $color-master-C1;
  }

  &>span {
    margin-right: 5px;
  }
}

.bottomDevice {
  display: flex;
  align-items: center;

  &>img {
    margin-right: 20px;
    height: 42px;
  }
}

.addBtn {
  display: flex;
  align-items: center;

  &>span:nth-of-type(1) {
    margin-right: 10px;
  }
}

.addBtnSmall {
  display: flex;
  align-items: center;

  &>span:nth-of-type(1) {
    margin-right: 5px;
  }
}

.deviceImg {
  position: relative;
  text-align: center;

  &>img {
    height: 100%;

    @media (max-height: 768px) {
      height: 90%;
    }
  }
}

.deviceStatus {
  position: absolute;
  left: 50%;
  top: 41%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: $color-master-C7;
  text-align: center;

  &>img {
    margin-bottom: 20px;
  }
}

.status {
  font-size: 14px;
  position: relative;
  padding-left: 10px;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    left: 0;
    top: 6px;
  }
}

.powerOn {
  color: $color-primary;

  &::before {
    background: $color-primary;
  }
}

.powerOff {
  color: $color-master-C5;

  &::before {
    background: $color-master-C5;
  }

  &>span {
    margin-left: 5px;
    cursor: pointer;
  }
}

.currentText {
  font-size: 20px;
  color: $color-master-C7;

  @media (max-height: 768px) {
    font-size: 18px;
  }
}

.pcSilentMode {
  position: absolute;
  background: $color-white;
  border-radius: 0 0 8px 8px;
  top: 218px;
  width: calc(100% - 23px);
  margin: 0 auto;
  left: 0;
  right: 0;
  font-size: 10px;
  color: $color-master-C7;
  padding: 6px 0;

  @media (max-height: 768px) {
    width: calc(100% - 18px);
    border-radius: 0 0 6px 6px;
    top: 155px;
  }

  &>img {
    margin-right: 5px;
  }
}

.mobileImg {
  position: relative;

  &>img {
    margin-bottom: 50px;
  }

  @media (max-height: 768px) {
    &>img {
      height: 300px;
    }
  }
}

.lockScreenInfo {
  position: absolute;
  top: 102px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: $color-master-C7;

  @media (max-height: 768px) {
    top: 76px;
  }

  &>img {
    width: 42px;

    @media (max-height: 768px) {
      width: 24px;
    }
  }

  &>div {
    width: 120px;
    background: $color-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    padding: 6px 0;
    margin: 15px auto 0 auto;

    @media (max-height: 768px) {
      font-size: 12px;
      padding: 3px 0;
      width: 105px;
    }

    &>img {
      margin-right: 5px;
    }
  }
}

.mobileLockScreen {
  top: 135px !important;

  @media (max-height: 768px) {
    top: 110px !important;
  }
}