@import "../../../../variables.module.scss";

.emptyRoot {
  display: flex;
  height: 100%;
  width: 100%;
}

.root {
  display: flex;
  min-height: calc(100% - 25px);
}

.container {
  background-color: $color-white;
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mockPhone {
  position: relative;
  background: rgba(255, 255, 255, 0);
  border: 3px solid $color-master-C4;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  min-width: 270px;
  min-height: 517px;
  padding: 35px 35px 97px 35px;
  margin-bottom: 50px;

  @media (max-height: 768px) {
    min-width: 226px;
    min-height: 412px;
    margin-bottom: 30px;
  }
}

@mixin borderIcon($topWith, $rightWith, $bottomWidth, $leftWith, $borderRadius) {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-right: $rightWith solid $color-master-C5;
  border-top: $topWith solid $color-master-C5;
  border-left: $leftWith solid $color-master-C5;
  border-bottom: $bottomWidth solid $color-master-C5;
  border-radius: $borderRadius;
}

.leftTop {
  @include borderIcon(1px, 0, 0, 1px, 10px 0 0 0);
  top: 20px;
  left: 20px;
}

.rightTop {
  @include borderIcon(1px, 1px, 0, 0, 0 10px 0 0);
  top: 20px;
  right: 20px;
}

.leftBottom {
  @include borderIcon(0, 0, 1px, 1px, 0 0 0 10px);
  bottom: 82px;
  left: 20px;
}

.rightBottom {
  @include borderIcon(0, 1px, 1px, 0, 0 0 10px 0);
  bottom: 82px;
  right: 20px;
}

.content {
  max-width: 200px;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screenShot {
  height: 355px;
  cursor: pointer;
  transition: all 0.2s;

  @media (max-height: 768px) {
    height: 265px;
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
}

.emptyContent {
  background-color: $color-white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  color: $color-master-C6;

  & > div {
    margin-top: 30px;
  }
}

.time {
  position: absolute;
  bottom: 30px;
  right: 20px;
  font-size: 12px;
  color: $color-master-C5;

  & > img {
    margin-right: 5px;
  }
}

.btn {
  width: 345px;
  border-radius: 8px;
}

.disabledBtn {
  opacity: 0.6;
  background-color: $color-primary !important;
  color: $color-white !important;
}

.zoomIcon {
  position: absolute;
  bottom: 97px;
  right: 35px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: rgba($color-master-C5, 0.6);
  cursor: pointer;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: $color-master-C5;
  }
}

.scaleImg {
  max-height: 735px;

  @media (max-height: 768px) {
    max-height: 620px;
  }
}

.cardList {
  flex: 1;
  overflow: auto;
  padding-left: 20px;
}

.cardListContent {
  background: $color-master-C1;
  border-radius: 4px;
  height: calc(100% - 60px);
}

.noData {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
}
