@import "../../../variables.module.scss";

.container {
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  width: 170px;
  height: 185px;
  padding: 2px;
  margin-bottom: 120px;
  margin-left: 20px;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.20);
  background-color: $color-white;

  @media (max-width: 1024px) {
    width: 130px;
    height: 145px;
  }

  div {
    display: inline-block;
  }

  &::after {
    content: "";
    left: 1px;
    position: absolute;
    width: 0;
    top: 75px;
    height: 0;
    box-sizing: border-box;
    border: 1em solid $color-white;
    transform-origin: 0 0;
    transform: rotate(45deg);
    box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.05);

    @media (max-width: 1024px) {
      top: 40px;
      border: 0.8em solid $color-white;
    }
  }
}

.desc {
  font-size: 14px;
  color: $color-master-C7;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
}

.childContainer {
  position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 18px 15px 15px 15px;
  margin-bottom: 120px;
  margin-left: 20px;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.20);
  background-color: #F0FDF9;

  &::after {
    content: "";
    left: -40px;
    position: absolute;
    top: 75px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-left: 20px solid transparent;
    border-right: 20px solid #F0FDF9;
    border-bottom: 20px solid transparent;
  }
}

.tabContainer {
  display: flex;
  background-color: white;
}

.title {
  font-size: 18px;
  color: $color-primary;
  font-weight: 500;
  margin-bottom: 14px;
}

.tabList {
  display: flex;
  flex-direction: column;
  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 16px 10px;
    cursor: pointer;


    & > img {
      width: 24px;
      margin-right: 5px;
    }
  }
}

.greenTab {
  background: #DAFAF2;
  &:hover {
    background: #CDF9ED;
  }
}

.blueTab {
  background: rgba(81, 146, 255, 0.16);
  &:hover {
    background: #C8DDFF;
  }
}

.smallTabItem {
  font-size: 10px;
  padding: 10px 10px !important;
  min-width: 70px;

  & > img {
    width: 16px !important;
  }
}

.activeTab {
  position: relative;
  background-color: white !important;
  color: $color-master-C7;
  font-weight: 500;
}

.activeTabLine {
  position: absolute;
  left: 0;
  height: 100%;
  width: 4px;
  background: $color-primary;
}

.greenTabActiveLine {
  background-color: $color-primary;
}

.blueTabActiveLine {
  background-color: #5192FF;;
}

.desc {
  font-size: 12px;
  color: $color-master-C6;
  padding: 10px 10px 1px 10px;
  background: white;

  & > span {
    color: $color-master-C7;
    font-weight: 500;
  }

  & > img {
    width: 12px;
    margin-right: 5px;
  }
}

.tabContent {
  width: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}