@import "../../../variables.module.scss";

.container {
  position: relative;
  padding: 30px 0;
  border-bottom: 1px solid $color-master-C4;
}

.optimize {
  position: absolute;
  top: 20px;
  right: 0;
}

.operation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  color: $color-master-C6;

  & > div:nth-of-type(1) {
    display: inline-flex;
    align-items: center;
  }

  & button {
    width: 120px;
    margin-right: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & svg {
      margin-right: 6px;
    }
  }
}

.name {
  font-size: 16px;
  color: $color-master-C7;
  margin-bottom: 15px;
}

.info {
  color: $color-master-C7;
}

.expend {
  color: $color-primary;
  cursor: pointer;

  & svg {
    margin-left: 5px;
  }
}

.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.showAll {
  white-space: pre-wrap;
  word-break: break-all;
}

.time {
  color: $color-master-C5;
}

.delete {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $color-master-C5;
  }

  & > img {
    margin-right: 5px;
  }
}

.likeBtn {
  background: $color-primary-lightest;
  border: 1px solid $color-primary-lightest;
  color: $color-primary;
}
