.vncContainer {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.vncViewerShow {
  display: visible;
  width: 100%;
  height: 100%;
}

.vncElement {
  width: 100%;
  height: 100%;
}

.vncViewerHide {
  display: none;
}

.vncLoader {
  max-width: 720px;
  width: 720px;
  margin-top: 200px;
}
