@import "../../../variables.module.scss";

.tips {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: $color-master-C7;
  margin-bottom: 40px;
  margin-top: 22px;
}

.title {
  font-size: 14px;
  color: $color-master-C6;
}

.desc {
  font-size: 14px;
  color: $color-master-C7;

  & > span:nth-of-type(1) {
    margin-right: 30px;
  }

  & > span {
    display: inline-flex;
    align-items: center;

    & > span {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      background: $color-master-C2;
      margin-right: 10px;
    }
  }
}

.desc-yellow {
  background: rgba($color: $color-function-warning, $alpha: 0.6) !important;
}

.desc-green {
  background: $color-primary !important;
}

.amContainer {
  margin-bottom: 60px;
  margin-right: 24px;
}

.pmContainer {
  margin-right: 24px;
}

.selectedTime {
  display: flex;
  margin-top: 50px;
  font-size: 14px;
  color: $color-master-C6;
  padding-bottom: 20px;

  & > span {
    flex: 1;
    margin-top: 4px;
  }

  & > div {
    flex: 12;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    overflow: auto;

    @media (max-width: 1600px) {
      flex: 10;
    }
    @media (max-width: 1024px) {
      flex: 7;
    }
  }
}


.selectedTimeItem {
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 14px;
  font-weight: 500;
}

.timeItem-yellow {
  background: rgba($color: $color-function-warning, $alpha: 0.06);
  border: 1px solid $color-function-warning;
  color: $color-function-warning;
}

.timeItem-green {
  background: rgba($color: $color-primary, $alpha: 0.06);
  border: 1px solid $color-primary;
  color: $color-primary;
}

.checkbox {
  display: flex;
}