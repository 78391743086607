@import "../../../variables.module.scss";

.tableContainer {
  background: $color-white;
  border-radius: 4px;
  padding: 30px;

  &>div:nth-of-type(1) {
    font-size: 16px;
    color: $color-master-C7;
    font-weight: 500;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-master-C3;
    margin-bottom: 20px;
  }
}

.topSpace {
  margin-top: 20px;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  background: $color-master-C1;
  padding: 14px 20px;
  font-size: 14px;
  color: $color-master-C7;
  font-weight: 600;

  &>span:not(:first-child) {
    flex: 1;
  }
}

.tableItem {
  display: flex;
  justify-content: space-between;
  padding: 14px 20px;
  font-size: 14px;
  color: $color-master-C7;
  box-shadow: inset 0 -1px 0 0 $color-master-C3;

  &>span:not(:first-child) {
    flex: 1;
  }
}

.dataEmpty {
  font-size: 14px;
  color: $color-master-C5;
  text-align: center;
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &>img {
    margin-right: 5px;
  }
}

.orderState {
  display: flex;
  align-items: center;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $color-primary;
  margin-right: 10px;
}

.container {
  height: 100%;
  overflow: auto;
}

.groupBuyDetail {
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-inline-start: 0;

  li {
    background: rgba(248, 248, 248, 0.60);
    padding: 20px 56px;

    &>span {
      font-size: 14px;
      color: $color-master-C6;

      &:first-child {
        margin-right: 20px;
      }

      &:nth-child(2) {
        font-weight: 600;
        display: inline-block;
        width: 140px;
      }

      &:last-child {
        color: $color-master-C5;
      }
    }
  }
}

.defaultTag,
.successTag {
  padding: 1px 7px;
  margin-left: 20px;
  font-weight: 500;
  font-size: 12px !important;
  background: $color-white;
  border-radius: 2px;
}

.defaultTag {
  border: 1px solid $color-master-C4;
  color: $color-master-C5 !important;
}

.successTag {
  border: 1px solid $color-primary;
  color: $color-primary !important;
}

.copyText {
  margin-left: 12px;
  cursor: pointer;
}

.codeUsed {
  opacity: 0.5;
}

.waitForUse {
  font-size: 14px;
  color: $color-primary;
}

.used {
  font-size: 14px;
  color: $color-master-C5;
}

.detailImg {
  width: 16px;
  height: 16px; 
  cursor: pointer;
}

.detailImgDown {
  transform: rotate(180deg);
}