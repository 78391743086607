@import "../../../variables.module.scss";

.container {
  padding-top: 60px;
  width: 800px;
  margin: 0 auto;
  font-size: 16px;
  color: $color-master-C6;
}

.title {
  font-size: 38px;
  color: $color-master-C7;
  margin: 100px 0 60px;
  text-align: center;
  font-weight: normal;
}

.content {
  letter-spacing: 1px;

  & > div {
    margin-bottom: 40px;
  }
}

.mobileContent {
  letter-spacing: 1px;

  & > div {
    margin-bottom: 20px;
  }
}

.connection {
  margin-bottom: 120px;
  letter-spacing: 1px;

  & > div {
    margin-bottom: 20px;
  }
}

.mobileContainer {
  padding: 27px 20px 20px;
  letter-spacing: 1px;
}

.mobileTitle {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 28px;
}
