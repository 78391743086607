@import "../../variables.module.scss";

.container {
  position: fixed;
  top: 40px;
  background: $color-white;
  border-radius: 4px;
  padding: 9px 30px 9px 16px;
  left: 50%;
  display: flex;
  align-items: center;
  z-index: 9999;
  color: $color-master-C6;

  & > span {
    display: flex;
    align-items: center;

    & > svg {
      margin-right: 8px;
      font-size: 16px;
    }
  } 
}

.closableContent {
  padding: 9px 16px !important;
  width: 616px;
  justify-content: space-between;

  @media (max-width: 616px) {
    width: calc(100% - 20px);
    margin: 0 auto;
  }
}

.closeBtn {
  margin-left: 10px;
  cursor: pointer;
}

.message {
  width: 532px;

  @media (max-width: 616px) {
    width: calc(100% - 20px);
  }
}

.success {
  background-color: $color-primary-006;
  border: 1px solid rgba($color: $color-primary, $alpha: 0.6);

  & > span > svg {
    color: $color-primary;
  }
}

.info {
  background-color: $color-function-info-006;
  border: 1px solid rgba($color: $color-function-info, $alpha: 0.6);

  & > span > svg {
    color: $color-function-info;
  }
}

.warning {
  background-color: $color-function-warning-006;
  border: 1px solid rgba($color: $color-function-warning, $alpha: 0.6);

  & > span > svg {
    color: $color-function-warning;
  }
}

.error {
  background-color: $color-function-error-006;
  border: 1px solid rgba($color: $color-function-error, $alpha: 0.6);

  & > span > svg {
    color: $color-function-error;
  }
}
