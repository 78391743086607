@import "../../variables.module.scss";

.container {
  position: relative;
  padding: 7px 0;
  display: inline-flex;
  justify-content: space-between;
  background: $color-master-C1;
  border-radius: 4px;
  width: 144px;

  background: $color-master-C3;
  box-shadow: inset 0 2px 4px 0 rgba(0,0,0,0.15);

  & > div {
    flex: 1;
    font-size: 16px;
    width: 70px;
    z-index: 1;
    text-align: center;
    cursor: pointer;
  }
}

.slider {
  position: absolute;
  display: inline-block;
  height: 34px;
  width: 70px;
  top: 3px;
  background: $color-white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
  border-radius: 4px;
}

.leftSlider {
  left: 2px;
}

.leftSliderAnimation {
  animation-name: sliderToLeft;
  animation-duration: .3s;
}

.rightSlider {
  right: 2px;
}

.rightSliderAnimation {
  animation-name: sliderToRight;
  animation-duration: .3s;
}

.activeText {
  color: $color-primary;
}

@keyframes sliderToLeft {
  from { left: 72px }
  to { left: 2px }
}

@keyframes sliderToRight {
  from { left: 2px }
  to { left: 72px }
}
