@import "../../../variables.module.scss";

.bannerTextContainer {
  background-image: url('./images/banner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 48.6vw;
  margin-bottom: 70px;
  padding-top: 9vw;
  padding-left: 15%;


  @media (max-width: 1440px) {
    padding-left: 12.5%;
  }

  @media (max-width: 1024px) {
    padding-left: 7.8%;
  }

  & > img {
    width: 22vw;
  }
}

.horizontalDividingLine {
  background: $color-primary;
  border-radius: 4px;
  width: 80px;
  height: 6px;
  margin: 20px 0 4vw 0;
}

.contentContainer {
  margin: 0 15%;

  @media (max-width: 1440px) {
    margin: 0 12.5%;
  }

  @media (max-width: 1024px) {
    margin: 0 7.8%;
  }
}

.title {
  font-size: 40px;
  color: $color-master-C7;
  text-align: center;
}

.desc {
  margin-top: 15px;
  font-size: 20px;
  color: $color-master-C6;
  text-align: center;
}

.priceContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0 30px 0;

 

  & > div:not(:first-child) {
    margin-left: 30px;
  }
}

.functionContainer {
  display: flex;
  justify-content: space-between;
  margin: 60px 0 150px 0;

  & > div:last-child {
    margin-left: 60px;
  }
}

.received {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 174px;
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;
  opacity: 0.5;
  background: $color-primary;
  box-shadow: 4px 8px 12px 0 rgba(42,194,139,0.20);
  border-radius: 8px;
}

.serviceInfo {
  margin-top: 5vw;
}

.agencyContainer {
  margin-top: 40px;

  & > img {
    width: 100%;
  }
}

.groupByContainer {
  background: $color-white;
  box-shadow: 0 8px 21px 0 rgba(0,0,0,0.10);
  border-radius: 8px;
  padding: 5px 0;
  height: 150px;
  margin-bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:nth-of-type(1) {
    display: flex;
    align-items: center;

    & > img {
      margin-right: 60px;
    }
  }
}

.groupByTitle {
  font-size: 24px;
  color: $color-master-C7;
  font-weight: 500;
}

.groupByLine {
  display: inline-block;
  background: $color-primary;
  border-radius: 4px;
  width: 33px;
  height: 4px;
  margin: 10px 0 23px 0;
}

.groupByDesc {
  font-size: 16px;
  color: $color-master-C6;

  & > span {
    font-size: 32px;
    color: #FF9358;
    font-family: Helvetica-Bold;
    font-weight: bold;
    margin: 0 5px;
  }
}

.groupByPrice {
  margin-bottom: 14px;
  font-size: 14px;
  color: $color-master-C7;
  text-align: center;

  & > span {
    color: $color-function-warning;
    font-size: 20px;
    font-weight: bold;
  }

  & > span:nth-of-type(1) {
    font-family: Helvetica-Bold;
    font-size: 40px;
    margin: 0 5px;
  }
}

.groupByLeft {
  padding-left: calc((((100% - 60px)/3) - 192px) /2);
}

.groupByRight {
  width: calc((100% - 60px)/3);
  margin-bottom: 10px;
  text-align: center;
}

.tabContent {
  background: $color-white;
  border: 1px solid rgba(30,211,143,1);
  border-radius: 20px;
  padding: 10px 0;
  margin: 20px 40px;
  text-align: center;
  color: $color-primary;
}