@import "../../variables.module.scss";


.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
  color: $color-master-C7;
  min-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.default-container {
  min-height: 20px;
}

.large-container {
  min-height: 26px;
}

.small-container {
  min-height: 14px;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

@mixin checkmark($color) {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid $color;
  border-radius: 4px;

  &::after {
    content: "";
    position: absolute;
    left: 6px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    display: none;
  }
}

.yellow {
  @include checkmark($color-function-warning);
}

.container input:checked ~ .yellow {
  background-color: $color-function-warning;

  &::after {
    display: block;
  }
}

.blue {
  @include checkmark($color-function-info);
}

.container input:checked ~ .blue {
  background-color: $color-function-info;

  &::after {
    display: block;
  }
}

.green {
  @include checkmark($color-master-C4);
}

.container input:checked ~ .green {
  background-color: $color-primary;

  &::after {
    display: block;
  }
}

.default-box {
  height: 20px;
  width: 20px;

  &::after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
  }
}

.large-box {
  height: 26px;
  width: 26px;

  &::after {
    left: 8px;
    top: 3px;
    width: 8px;
    height: 14px;
  }
}

.small-box {
  height: 14px;
  width: 14px;
  top: 2px;
  left: 15px;

  &::after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 6px;
  }
}
