@import '../../../../variables.module.scss';

html {
  font-size: 100px;
}

.headerTop {
  margin-top: 0.74rem;
}

.header {
  background: $color-white;
  padding: 0.26rem 0.24rem;
  margin-bottom: 0.3rem;
}

.content {
  text-align: center;
}

.banner {
  width: 100%;
}

.title {
  font-size: 0.21rem;
  color: $color-master-C7;
  margin: 0.3rem 0 0.2rem;
}

.desc {
  font-size: 0.12rem;
  color: $color-master-C6;
  margin-bottom: 0.1rem;
}

.registerBtn {
  width: 1.67rem;
  margin-bottom: 0.25rem;
}

.subTitle {
  font-size: 0.2rem;
  color: $color-master-C7;
  margin: 0.53rem 0 0.1rem;
}

.subDesc {
  font-size: 0.12rem;
  color: $color-master-C7;
}

.funcs {
  background-image: url('images/funcBg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 0.64rem;
}

.funcContainer {
  padding: 0 0.25rem;
  margin-top: 0.4rem;

  & > img {
    width: 100%;
  }
}

.funcTitle {
  font-size: 18px;
  color: $color-master-C7;
  letter-spacing: 1.5px;
  margin-bottom: 5px;
}

.funcDesc {
  font-size: 12px;
  color: $color-master-C6;
  margin-bottom: 0.2rem;
}

.line {
  height: 4px;
  width: 50px;
  background: $color-primary;
  border-radius: 2px;
  margin: 0 auto 8px;
}

.footer {
  font-size: 0.12rem;
  color: $color-master-C5;
  padding: 0.3rem 0;

  & a {
    color: $color-master-C5;
  }

  & > div:not(:last-child) {
    margin-bottom: 8px;
  }
}

.loginRegister {
  position: fixed;
  bottom: 0;
  display: flex;
  height: 0.5rem;
  width: 100%;
  box-shadow: 0 -6px 8px 0 rgba(0, 0, 0, 0.12);

  & > a {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.14rem;
  }

  & > a:nth-of-type(1) {
    background: $color-primary;
    color: $color-white;
  }

  & > a:nth-of-type(2) {
    background: $color-white;
    color: $color-master-C6;
  }
}

.footerBottom {
  margin-bottom: 0.5rem;
}

.websiteUrl {
  width: 1.59rem;
}

.wechat {
  margin-bottom: 0.3rem;
}

.mask {
  position: fixed;
  top: 0;
  background-color: rgba($color: $color-black, $alpha: 0.5);
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
}

.cancelBtn {
  position: absolute;
  top: calc((100vh - 126vw) / 2 - 0.2rem);
  right: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.2rem;
  height: 0.2rem;
  background: $color-white;
  border-radius: 50%;
}

.containBox {
  position: relative;
}

.context {
  width: 80vw;
}

.modelTitle {
  font-size: 16px;
  color: $color-master-C7;
  font-weight: 600;
  font-weight: 600;
}

.modelDesc {
  font-size: 13px;
  color: $color-master-C5;
  margin: 12px auto 24px auto;
}

.qrcodeBackground {
  width: 140px;
  height: 140px;
  margin: 0 auto;
  background: url(./images/qrcode.png) no-repeat;
  background-size: 100% 100%;
  padding: 5px;
}

.modelContainer {
  width: 86vw;
  background: url(./images/dialogBackground.png) no-repeat;
  background-size: 100% 100%;
  padding: 40vw 24px 24px;
  border-radius: 8px;
  text-align: center;
}

.downloadBtn {
  width: 80%;
  margin: 0 auto;
  background: $color-primary;
  border-radius: 0.28rem;
  font-size: 0.15rem;
  color: $color-white;
  padding: 0.12rem 0;
  text-align: center;
  cursor: pointer;
}

.cancelDownBtn {
  width: 80%;
  margin: 0 auto;
  border-radius: 0.28rem;
  font-size: 0.15rem;
  color: $color-master-C6;
  padding: 0.12rem 0 0;
  text-align: center;
  cursor: pointer;
}

.qsHeader {
  position: absolute;
  left: 0;
  height: 0.7rem;
  display: flex;
  align-items: center;
  padding: 0 0.24rem;
}

.qsTitle {
  text-align: center;

  & > img {
    width: 209px;
  }
}

.qsTitleDesc {
  font-size: 12px;
  color: $color-master-C6;
  text-align: center;
  margin-top: 20px;
}

.qsMobileWechat {
  text-align: center;
  img {
    width: 100%;
  }
}

.qsFamilyImg {
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
  img {
    width: calc(100% - 0.5rem);
    margin: 0.25rem 0;
  }
}

.qsSecondTitle {
  margin-top: 50px;
  font-size: 20px;
  font-weight: 600;
  color: $color-master-C7;
}

.qsSecondDesc {
  font-size: 12px;
  color: $color-master-C6;
  text-align: center;
  margin-top: 10px;
}

.qsFCardContainer {
  margin-top: 40px;
}

.qsFCardTitle {
  font-size: 18px;
  color: $color-master-C7;
  letter-spacing: 1.5px;
  text-align: center;
}

.qsFCardDesc {
  font-size: 12px;
  color: $color-master-C6;
  text-align: center;
}

.loginBtn {
  position: fixed;
  margin: 0px auto;
  margin: 0 auto;
  width: 240px;
  left: 0;
  right: 0;
  bottom: 40px;
}

.loginSuccess {
  text-align: center;
  width: 100vw;
  height: 64px;
  background-color: #7d8fc4;
  color: $color-white;
  padding-top: 10px;
}

.successTop {
  font-size: 14px;
  img {
    vertical-align: middle;
    margin-right: 5px;
  }
}

.successBottom {
  margin-top: 6px;
  font-size: 12px;
}
.cancelDialogBtn {
  width: 7vw;
  height: 7vw;
  top: 5vw;
  right: 5vw;
  position: absolute;
  cursor: pointer;
}
