@import '../../variables.module.scss';

// QsButtonFixedSize style
@mixin fixedSizeBasic($bgColor, $color, $borderColor) {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 48px;
  font-size: 18px;
  font-weight: 500;
  background: $bgColor;
  color: $color;
  border: 1px solid $borderColor;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.fixedSize-primary {
  @include fixedSizeBasic($color-primary, $color-white, transparent);
  box-shadow: 2px 4px 8px 0 rgba(30, 211, 143, 0.3);
  cursor: pointer;

  &:hover {
    background-color: $color-primary-dark;
  }
}

.fixedSize-primary-disable {
  @include fixedSizeBasic($color-primary, $color-white, transparent);
  box-shadow: 2px 4px 8px 0 rgba(30, 211, 143, 0.3);
  cursor: not-allowed;

  background-color: rgba($color: $color-primary, $alpha: 0.5);
}

.fixedSize-primaryOutline {
  @include fixedSizeBasic($color-white, $color-primary, $color-primary);
  cursor: pointer;

  &:hover {
    background-color: $color-primary-008;
  }
}

.fixedSize-primaryOutline-disable {
  @include fixedSizeBasic($color-white, $color-primary, $color-primary);
  opacity: 0.5;
  cursor: not-allowed;
}

.fixedSize-normal {
  width: 450px;
}

.fixedSize-middle {
  width: 350px;
}

.fixedSize-small {
  width: 200px;
}

// QsButtonMobileLogin style
@mixin mobileLoginButton($bgColor, $color, $borderColor) {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  background: $bgColor;
  color: $color;
  border: 1px solid $borderColor;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mobile-login-primary {
  @include mobileLoginButton($color-primary, $color-white, transparent);
  box-shadow: 2px 4px 8px 0 rgba(30, 211, 143, 0.3);
  cursor: pointer;

  &:hover {
    background-color: $color-primary-dark;
  }
}

.mobile-login-primary-disable {
  @include mobileLoginButton($color-primary, $color-white, transparent);
  box-shadow: 2px 4px 8px 0 rgba(30, 211, 143, 0.3);
  cursor: not-allowed;

  background-color: rgba($color: $color-primary, $alpha: 0.5);
}

// QsButton style
@mixin basic($bgColor, $color, $borderColor) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $bgColor;
  color: $color;
  border: 1px solid $borderColor;
}

// QsButton type: default
.default {
  @include basic($color-master-C1, $color-master-C5, $color-master-C1);
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: $color-master-C6;
  }
}

.default-disable {
  @include basic(
    $color-master-C1,
    rgba($color: $color-master-C5, $alpha: 0.5),
    $color-master-C1
  );
  cursor: not-allowed;
}

// QsButton type: primary
.primary {
  @include basic($color-primary, $color-white, transparent);
  cursor: pointer;

  &:hover {
    background-color: $color-primary-dark;
  }
}

.primary-disable {
  @include basic(rgba($color: $color-primary, $alpha: 0.5), $color-white, transparent);
  cursor: not-allowed;
}

// QsButton type: outline
.outline {
  @include basic($color-white, $color-master-C7, $color-master-C4);
  cursor: pointer;

  &:hover {
    background: $color-master-C1;
  }
}

.outline-disable {
  @include basic(
    $color-white,
    rgba($color: $color-master-C5, $alpha: 0.5),
    $color-master-C4
  );
  cursor: not-allowed;
}

// QsButton type: primaryOutline
.primaryOutline {
  @include basic($color-white, $color-primary, $color-primary);
  cursor: pointer;

  &:hover {
    @include basic($color-primary-008, $color-primary, $color-primary);
  }
}

.primaryOutline-disable {
  @include basic($color-white, $color-primary, $color-primary);
  opacity: 0.5;
  cursor: not-allowed;
}

// QsButton type: outlinePrimary
.outlinePrimary {
  @include basic($color-white, $color-master-C7, $color-master-C4);
  cursor: pointer;

  &:hover {
    @include basic($color-white, $color-primary, $color-primary);
  }
}

.outlinePrimary-disable {
  @include basic(
    $color-white,
    rgba($color: $color-master-C7, $alpha: 0.5),
    $color-master-C4
  );
  cursor: not-allowed;
}

.size-normal {
  font-size: 14px;
  padding: 6px 16px;
  border-radius: 4px;
}

.size-small {
  font-size: 12px;
  padding: 4px 16px;
  border-radius: 2px;
}

// QsButtonFixedHeight style
@mixin fixedHeightBasic($bgColor, $color, $borderColor) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  background: $bgColor;
  color: $color;
  border: 1px dashed $borderColor;
  border-radius: 4px;
  font-size: 14px;
}

// QsButtonFixedHeight, type: primaryOutline
.fixedHeight-primaryOutline {
  @include fixedHeightBasic($color-white, $color-primary, $color-primary);
  cursor: pointer;

  &:hover {
    background-color: $color-primary-008;
  }
}

.fixedHeight-primaryOutline-disable {
  @include fixedHeightBasic($color-white, $color-primary, $color-primary);
  opacity: 0.5;
  cursor: not-allowed;
}

// QsButtonFixedHeight, type: outline
.fixedHeight-outline {
  @include fixedHeightBasic($color-white, $color-master-C7, $color-master-C4);
  cursor: pointer;

  &:hover {
    background-color: rgba($color: $color-master-C1, $alpha: 0.8);
  }
}

.fixedHeight-outline-disable {
  @include fixedHeightBasic(
    $color-white,
    rgba($color: $color-master-C7, $alpha: 0.5),
    $color-master-C4
  );
  opacity: 0.5;
  cursor: not-allowed;
}

@mixin textBasic($color, $hoverColor) {
  font-size: 14px;
  color: $color;

  &:hover {
    color: $hoverColor;
  }
}

.text-primary {
  @include textBasic($color-primary, $color-primary-dark);
  cursor: pointer;
}
.text-primary-sem {
  @include textBasic($color-primary, $color-primary-dark);
  cursor: pointer;
  color: $color-master-C7;
  min-width: 98px !important;
  height: 40px;
  text-align: center;
  margin-left: 15px;
  border: solid 1px $color-master-C4;
  line-height: 40px;
}

.text-primary-disable {
  @include textBasic(
    rgba($color: $color-primary, $alpha: 0.5),
    rgba($color: $color-primary, $alpha: 0.5)
  );
  cursor: not-allowed;
}
.text-primary-sem-disable {
  @include textBasic(
    rgba($color: $color-primary, $alpha: 0.5),
    rgba($color: $color-primary, $alpha: 0.5)
  );
  min-width: 98px !important;
  border: solid 1px $color-master-C4;
  margin-left: 15px;
  line-height: 40px;
  text-align: center;
  height: 40px;
  cursor: not-allowed;
}

.text-secondary {
  @include textBasic($color-master-C6, $color-primary);
  cursor: pointer;
}

.text-secondary-disable {
  @include textBasic(
    rgba($color: $color-master-C6, $alpha: 0.5),
    rgba($color: $color-master-C6, $alpha: 0.5)
  );
  cursor: not-allowed;
}

.sem {
  border-radius: 24px;
}
