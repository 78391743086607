@import '../../../../variables.module.scss';

.container {
  position: relative;
  width: 100%;
}

@mixin selectCommon {
  font-size: 16px;
  color: $color-master-C5;
  cursor: pointer;

  & > span:first-child {
    margin-right: 10px;
  }

  &:hover {
    color: $color-master-C6;
  }
}

.select {
  @include selectCommon();
}

.borderStyle {
  display: inline-flex;
  background: $color-white;
  border: 1px solid $color-master-C4;
  border-radius: 4px;
  padding: 3px 14px;
  align-items: center;

  & > span:first-child {
    margin-right: 5px;
  }

  & > span:last-child {
    display: flex;
  }
}

.selectActive {
  @include selectCommon();
  color: $color-master-C6;
}

.selectPanel {
  position: absolute;
  top: 45px;
  background: $color-white;
  box-shadow: 2px 4px 8px 0 rgba(0,0,0,0.20);
  border-radius: 8px;
  z-index: 105;
}

.options {
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 0 !important;

  & > li:not(:last-child) {
    margin-bottom: 2px;
  }

  li {
    background-color: $color-white;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    padding: 7px 10px;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: $color-master-C1;
    }
  }
}

.active {
  background-color: $color-master-C1 !important;
  color: $color-primary !important;
}

