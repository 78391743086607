@import "../../../variables.module.scss";

.container {
  width: 100%;
  display: flex;
  height: 100%;
  background-color: $color-white;
  overflow: auto;
}

