@import "../../../variables.module.scss";

.bindMobileCard {
  background: $color-white;
  border-radius: 4px;
  padding: 40px 30px 30px 30px;

  .bindMobileContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .bindMobileCardTitle {
    font-size: 20px;
    color: $color-master-C7;
    text-align: center;
    margin-bottom: 30px;
  }

  .bindMobileDownload {
    background: rgba(81,146,255,0.10);
    border-radius: 8px;
    padding: 40px 15px 20px 15px;
    width: 260px;
    margin-right: 20px;

    position: relative;
  }

  .bindMobileStep1 {
    position: absolute;
    top: 10px;
    left: 0;
    background: #5192FF;
    border-radius: 0 100px 100px 0;
    font-size: 14px;
    color: $color-white;
    padding: 2px 10px;
  }

  .bindMobileStep2 {
    position: absolute;
    top: 10px;
    left: 0;
    background: #59E9C4;
    border-radius: 0 100px 100px 0;
    font-size: 14px;
    color: $color-white;
    padding: 2px 10px;
  }

  .bindMobileDownloadTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      font-weight: bold;
      font-size: 18px;
      color: #5192FF;
      margin-right: 5px;
    }

    & > img {
      height: 18px;
    }
  }

  .bindMobileBinding {
    background: rgba(104,237,203,0.10);
    border-radius: 4px;
    padding: 40px 15px 20px 15px;
    width: 260px;
    margin-left: 20px;
    position: relative;
  }

  .bindMobileBindingTitle {
    display: flex;
    align-items: center;
    justify-content: center;

    & > span {
      font-weight: bold;
      font-size: 18px;
      color: #55E3BE;
      margin-right: 5px;
    }

    & > img {
      height: 18px;
    }
  }

  .bindMobileCardLine {
    height: 318px;
    width: 1px;
    background-color: $color-master-C4;
  }

  .bindMobileCardQrCode {
    margin: 15px auto 25px;
    text-align: center;

    position: relative;
    display: inline-block;
  }

  .borderCorner,
  .borderRightCorner {
    z-index: 1000;
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0);
  }

  .borderCorner {
    border: 2px solid $color-primary;
  }

  .borderRightCorner {
    border: 2px solid #55E3BE;
  }

  .borderCornerLeftTop {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
    border-top-left-radius: 4px;
  }

  .borderCornerRightTop {
    top: 0;
    right: 0;
    border-left: none;
    border-bottom: none;
    border-top-right-radius: 4px;
  }

  .borderCornerLeftBottom {
    bottom: 4px;
    left: 0;
    border-right: none;
    border-top: none;
    border-bottom-left-radius: 4px;
  }

  .borderCornerRightBottom {
    bottom: 4px;
    right: 0;
    border-left: none;
    border-top: none;
    border-bottom-right-radius: 4px;
  }

  .bindMobileCardDesc {
    font-size: 14px;
    color: rgba(51, 51, 51, 0.85);

    & > span {
      color: $color-master-C7;
      font-weight: 500;
    }
  }
}
