@import "../../../../variables.module.scss";

.imageContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3px;
  width: 25%;
  padding-top: 55px;

  @media (max-width: 1600px) {
    padding-top: 42px;
  }

  @media (max-width: 1440px) {
    padding-top: 40px;
  }

  @media (max-width: 1366px) {
    padding-top: 30px;
  }

  @media (max-width: 1024px) {
    padding-top: 25px;
  }
}

.imageCard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: $color-white;
  border: 1px solid $color-master-C3;
  border-radius: 8px;
  padding: 8px;

  & > img {
    max-height: 100%;
    max-width: 100%;
    display: block;
    position: relative;
  }

  &:hover {
    .mask {
      visibility: visible;
      opacity: 1;
    }
  }

  & > .mask {
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background: rgba($color-black, 0.5);
    box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
  }

  .zoomIcon {
    color: $color-white;
    font-size: 40px;
  }

  .deleteIcon {
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 25px;
    color: $color-function-error;
  }
}

@media (min-width: 1366px) {
  .imageCard {
    width: 80px;
    height: 80px;

    & > .mask {
      width: 80px;
      height: 80px;
    }
  }
}

@media (min-width: 1440px) {
  .imageCard {
    width: 110px;
    height: 110px;

    & > .mask {
      width: 110px;
      height: 110px;
    }
  }
}

@media (min-width: 1920px) {
  .imageCard {
    width: 135px;
    height: 135px;

    & > .mask {
      width: 135px;
      height: 135px;
    }
  }
}

.createTime {
  margin-top: 15px;

  @media (min-width: 1600px) {
    margin-top: 10px;
  }

  @media (min-width: 1920px) {
    margin-top: 10px;
  }

  & > span {
    font-size: 14px;
    color: $color-master-C6;
  }
}

.scaleImg {
  height: 75vh;
  max-width: 90vw;

  @media (max-width: 1024px) {
    height: 65vmin;
  }
}

.container {
  display: flex;
  justify-self: center;
  flex-wrap: wrap;
  // margin-left: 50px;
  animation: passIn 0.3s ease;

  @media (min-width: 1024px) {
    margin-left: 5px;
  }
}

@keyframes passIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
