@import "../../../../variables.module.scss";

html {
  font-size: 100px;
}

.header {
  background: $color-white;
  padding: 0.26rem 0.24rem;
  margin-bottom: 0.3rem
}

.container {
  text-align: center;
  color: $color-master-C6;

  & > img {
    margin-bottom: 42px;
  }
}

.content {
  padding: 0 0.4rem;
  font-size: 0.16rem;
  text-align: left;
  margin-bottom: 0.5rem;
}

.title {
  font-size: 0.18rem;
  color: $color-primary;
  font-weight: 600;
  margin: 0 5px;
}

.address {
  margin-top: 5px;
}
