@import "../../../variables.module.scss";

.container {
  padding-top: 60px;
  width: 800px;
  margin: 0 auto 120px;
  font-size: 16px;
  color: $color-master-C6;
}

.mobileContainer {
  padding: 27px 20px 20px;
  letter-spacing: 1px;
}

.header {
  margin-top: 100px;
  margin-bottom: 60px;
  font-size: 38px;
  color: $color-master-C7;
  text-align: center;
}

.mobileHeader {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 28px;
}

.title {
  font-weight: 600;
  color: $color-master-C7;
}

.mgb20 {
  margin-bottom: 20px;
}

.time {
  text-align: right;
}

.link {
  display: block;
  margin-bottom: 20px;
  color: $color-primary;
  cursor: pointer;

  &:hover {
    color: $color-primary-dark;
  }
}