@import "../../../variables.module.scss";

.switch {
  position: relative;
  display: inline-block;
  width: 300px;
  height: 40px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
  background: $color-white;
  border-radius: 30px;
  
  -webkit-transition: .4s;
  transition: .4s;

  & > span {
    font-size: 16px;
    color: $color-master-C6;
    opacity: 0.6;
  }

  .checkedText {
    font-size: 16px;
    color: $color-white;
    opacity: 0.9;
    font-weight: 600;
  }
}

.slider:before {
  position: absolute;
  content: "";
  height: 34px;
  left: 4px;
  bottom: 3px;
  background: $color-master-C7;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.30);
  border-radius: 30px;
  transition: .4s;
}

input:checked + .slider {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
  background: $color-white;
}

input:focus + .slider {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
}

input:checked + .slider:before {
  -webkit-transform: translateX(146px);
  -ms-transform: translateX(146px);
  transform: translateX(146px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;

}

.slider.round:before {
  border-radius: 30px;
  width: 147px;
}

