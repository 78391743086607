@import '../../../variables.module.scss';

.container {
  background: $color-white;
  border-radius: 10px;
  height: 100%;
  padding: 20px;
  overflow: hidden;
}

.content {
  height: calc(100vh - 236px);
  border-radius: 0 10px 10px 10px;
  background: $color-master-C1;
}

.tabTitle {
  display: flex;
  height: 100%;
  margin-left: 20px;

  span {
    font-size: 22px;
    color: $color-master-C7;
    display: flex;
    align-items: center;

    & > img {
      height: 32px;
      margin-right: 10px;
    }
  }
}

.titleText {
  width: 148px;
  display: inline-block !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
